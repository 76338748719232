import React, {useState, useEffect} from 'react'
import styles from './VideoClip.module.scss'
import { arrVideo } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'
import { CardVideo } from '../CardVideo/CardVideo'


const VideoClip = () => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(6);
                } else if (width < 980 && width >= 640) {
                    setMoviesTotal(4);
                        } else if (width <= 639) {
                            setMoviesTotal(4);
            }
        }
        getCards();
    }, [width])


    return (
        <section className={styles.main}>
            <div className={styles.box}>
                <h2 className={styles.title} >Видео о<span>  нашей работе</span></h2>
                <ul className={styles.list}>
                {arrVideo.map((item, index) =>{
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <CardVideo
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                />
                            ) 
                        }else {
                            return ''
                        }
                    })}
    
                </ul>
                <div className={styles.button_box}>
                    <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@MirStroitelstva02/videos' aria-label='ютуб канал'>ПЕРЕЙТИ НА НАШ КАНАЛ</a>
                </div>
                
            </div>
        </section>
    )
}

export default VideoClip