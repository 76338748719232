import React, {useEffect} from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import ListWorks from '../../components/ListWorks/ListWorks'
import AboutUs from '../../components/AboutUs/AboutUs'
import Callback from '../../components/Callback/Callback'
import Confidence from '../../components/Confidence/Confidence'
import Scheme from '../../components/Scheme/Scheme'

const Main = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />  
            <AboutUs/>
            <Confidence/>
            <ListWorks/>
            <Scheme
                isOpen={isOpen}
            />
            <Callback
                    popupOpen={popupOpen}
                />
        </section>
    )
}

export default Main