import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './MobilnyeBani.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/MobilnyeBani/mb-001/img_1.jpg'
import img_2 from '../../image/MobilnyeBani/mb-002/img_1.jpg'
import img_3 from '../../image/MobilnyeBani/mb-003/img_1.jpg'
import img_4 from '../../image/MobilnyeBani/mb-004/img_1.jpg'
import img_5 from '../../image/MobilnyeBani/mb-005/img_1.jpg'
import img_6 from '../../image/MobilnyeBani/mb-006/img_1.jpg'
import img_7 from '../../image/MobilnyeBani/mb-007/img_1.jpg'
import img_8 from '../../image/MobilnyeBani/mb-008/img_1.jpg'
import img_9 from '../../image/MobilnyeBani/mb-009/img_1.jpg'
import img_10 from '../../image/MobilnyeBani/mb-010/img_1.jpg'
import img_11 from '../../image/MobilnyeBani/mb-011/img_1.jpg'
import img_12 from '../../image/MobilnyeBani/mb-012/img_1.jpg'
import img_13 from '../../image/MobilnyeBani/mb-013/img_1.jpg'
import img_14 from '../../image/MobilnyeBani/mb-014/img_1.jpg'
import img_15 from '../../image/MobilnyeBani/mb-015/img_1.jpg'
import img_18 from '../../image/MobilnyeBani/mb-018/img_1.jpg'
import img_19 from '../../image/MobilnyeBani/mb-019/img_1.jpg'
import img_20 from '../../image/MobilnyeBani/mb-020/img_1.jpg'
import img_21 from '../../image/MobilnyeBani/mb-021/img_1.jpg'
import img_22 from '../../image/MobilnyeBani/mb-022/img_1.jpg'
import img_23 from '../../image/MobilnyeBani/mb-023/img_1.jpg'
import img_24 from '../../image/MobilnyeBani/mb-024/img_1.jpg'
import img_25 from '../../image/MobilnyeBani/mb-025/img_1.jpg'
import img_26 from '../../image/MobilnyeBani/mb-026/img_1.jpg'


///// массивы
import { arrMb1 } from '../../utils/mb'
import { arrMb2 } from '../../utils/mb'
import { arrMb3 } from '../../utils/mb'
import { arrMb4 } from '../../utils/mb'
import { arrMb5 } from '../../utils/mb'
import { arrMb6 } from '../../utils/mb'
import { arrMb7 } from '../../utils/mb'
import { arrMb8 } from '../../utils/mb'
import { arrMb9 } from '../../utils/mb'
import { arrMb10 } from '../../utils/mb'
import { arrMb11 } from '../../utils/mb'
import { arrMb12 } from '../../utils/mb'
import { arrMb13 } from '../../utils/mb'
import { arrMb14 } from '../../utils/mb'
import { arrMb15 } from '../../utils/mb'
import { arrMb18 } from '../../utils/mb'
import { arrMb19 } from '../../utils/mb'
import { arrMb20 } from '../../utils/mb'
import { arrMb21 } from '../../utils/mb'
import { arrMb22 } from '../../utils/mb'
import { arrMb23 } from '../../utils/mb'
import { arrMb24 } from '../../utils/mb'
import { arrMb25 } from '../../utils/mb'
import { arrMb26 } from '../../utils/mb'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const MobilnyeBani = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const house = 'Мобильная баня';

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Мобильные бани'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.title}>Готовые проекты  <span>мобильных бань</span></h2>
                    <ul className={styles.list}>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-001</h3>
                        <p className={styles.cell_text}>{house} 2.3х3 м.</p>
                        <p className={styles.cell_number}>от 340 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb1)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-002</h3>
                        <p className={styles.cell_text}>{house} 2.3х4 м.</p>
                        <p className={styles.cell_number}>от 360 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb2)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-003</h3>
                        <p className={styles.cell_text}>{house} 2.3х4 м.</p>
                        <p className={styles.cell_number}>от 350 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb3)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_4} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-004</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>от 380 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb4)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_5} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-005</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>от 370 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb5)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_6} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-006</h3>
                        <p className={styles.cell_text}>{house} 2.3х6 м.</p>
                        <p className={styles.cell_number}>от 400 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb6)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_7} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-007</h3>
                        <p className={styles.cell_text}>{house} 2.3х6 м.</p>
                        <p className={styles.cell_number}>от 390 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb7)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_8} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-008</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 420 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb8)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_9} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-009</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 460 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb9)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_10} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-010</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 430 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb10)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_11} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-011</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>380 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb11)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_12} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-012</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>от 410 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb12)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_13} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-013</h3>
                        <p className={styles.cell_text}>{house} 2.3х6 м.</p>
                        <p className={styles.cell_number}>от 400 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb13)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_14} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-014</h3>
                        <p className={styles.cell_text}>{house} 2.3х6 м.</p>
                        <p className={styles.cell_number}>от 430 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb14)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_15} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-015</h3>
                        <p className={styles.cell_text}>{house} 2.3х8 м.</p>
                        <p className={styles.cell_number}>от 490 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb15)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_18} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-018</h3>
                        <p className={styles.cell_text}>{house} 2.3х6 м.</p>
                        <p className={styles.cell_number}>от 430 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb18)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_19} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-019</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>от 400 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb19)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_20} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-020</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 460 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb20)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_21} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-021</h3>
                        <p className={styles.cell_text}>{house} 2.3х8 м.</p>
                        <p className={styles.cell_number}>от 490 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb21)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_22} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-022</h3>
                        <p className={styles.cell_text}>{house} 2.3х8 м.</p>
                        <p className={styles.cell_number}>от 490 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb22)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_23} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-023</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 460 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb23)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_24} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-024</h3>
                        <p className={styles.cell_text}>{house} 2.3х8 м.</p>
                        <p className={styles.cell_number}>от 490 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb24)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_25} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-025</h3>
                        <p className={styles.cell_text}>{house} 2.3х7 м.</p>
                        <p className={styles.cell_number}>от 460 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb25)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_26} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект МБ-026</h3>
                        <p className={styles.cell_text}>{house} 2.3х5 м.</p>
                        <p className={styles.cell_number}>от 400 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrMb26)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                </div>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default MobilnyeBani