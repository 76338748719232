import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Portfolio.module.scss'
import img_1 from '../../image/Portfolio/KarkasMytishchi/img_1.jpg'
import img_2 from '../../image/Portfolio/SipSerpuhov/img_1.jpg'
import img_3 from '../../image/Portfolio/SipDmitrov/img_4.jpg'
import img_4 from '../../image/Portfolio/Barnhouse/img_1.jpg'

import { arrPo1 } from '../../utils/portfolio'
import { arrPo2 } from '../../utils/portfolio'
import { arrPo3 } from '../../utils/portfolio'
import { arrPo4 } from '../../utils/portfolio'


const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const PortfolioList = ({isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <motion.section 
        className={styles.main}
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}> 
                    <motion.h2 className={styles.subtitle} variants={titleAnimation}>Подборка фотографий <span>с&#160;наших обьектов</span></motion.h2>
                    <ul className={styles.list}>
                    <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_1} alt='дизайн' />
                                <div className={styles.box_link}>
                                    <p className={styles.item_link} onClick={DkClick => { isImageOpen(arrPo1)}}>подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Каркасный дом</p>
                            <p className={styles.item_text}>г. Мытищи</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_2} alt='дизайн'/>
                                <div className={styles.box_link} >
                                    <p className={styles.item_link} onClick={DkClick => { isImageOpen(arrPo2)}}>подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Дом из сип панелей</p>
                            <p className={styles.item_text}>с. Скрылья Серпуховского района</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_3} alt='дизайн'/>
                                <div className={styles.box_link} >
                                    <p className={styles.item_link} onClick={DkClick => { isImageOpen(arrPo3)}}>подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Дом из сип панелей</p>
                            <p className={styles.item_text}>п. Будённовец, Дмитровский округ</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_4} alt='дизайн'/>
                                <div className={styles.box_link} >
                                    <p className={styles.item_link} onClick={DkClick => { isImageOpen(arrPo4)}}>подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Barnhouse</p>
                            <p className={styles.item_text}>КП "Артемовские Дачи"</p>
                        </motion.li>
                    </ul>
            </div>
        </motion.section>
    )
}

export default PortfolioList