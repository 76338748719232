import React, {useEffect} from 'react'
import styles from './OurServices.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import ListWorks from '../../components/ListWorks/ListWorks'
import Callback from '../../components/Callback/Callback'
import Scheme from '../../components/Scheme/Scheme'


const OurServices = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Каталог'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Строительство загородных <span>домов и бань под ключ</span> </h2>
                    <p className={styles.text_box}>Строительство дома или бани - это сложная и трудоемкая задача, с которой невозможно справиться без должной подготовки, знаний, опыта и специального оборудования. Любые ошибки, допущенные при разработке проекта и возведении, в дальнейшем приведут к серьезным неприятностям: проседанию фундамента, возникновению перекосов и трещин, ухудшению теплоизоляции и др. Чтобы не допустить подобных проблем, следует обращаться за помощью к профессионалам.</p>
                    <h3 className={styles.subtitle}>Что входит в <span>состав работ?</span></h3>
                    <p className={styles.text_box}>Строительство частного дома или бани проводится в определенной последовательности:</p>
                    <p className={styles.text_box}><span>Консультация.</span> На первом этапе проводится собеседование. Вы сообщаете свои требования, специалисты оказывают помощь в выборе подходящего проекта, рассчитывают предварительную стоимость, дают ответы на все интересующие вопросы.</p>
                    <p className={styles.text_box}><span>Разработка проектной документации.</span> Если в нашем каталоге не удалось найти оптимальный вариант, мы займемся разработкой индивидуального проекта. Схемы и чертежи будут сделаны в индивидуальном порядке. Это особенно удобно в случае, если Вы хотите уложиться в определенную денежную сумму или Вас интересует нестандартная планировка.</p>
                    <p className={styles.text_box}><span>Покупка и доставка стройматериалов.</span> Все материалы, инструменты и оборудование, необходимые для возведения строения, мы закупим и перевезем на стройплощадку при помощи собственного грузового транспорта. Вам не придется самостоятельно искать поставщиков стройматериалов и обращаться в сторонние транспортные организации, чтобы доставить купленные товары на объект.</p>
                    <p className={styles.text_box}><span>Возведение.</span> Строительство здания под ключ начинается с подготовки фундамента. Затем возводится каркас, устанавливаются несущие конструкции. После укладки стен и монтажа кровли проводится внутренняя и наружная отделка, в доме прокладывают инженерные системы (электричество, интернет, водо- и газопровод, вентиляцию, канализацию и тп.)</p>
                    <h3 className={styles.subtitle}>Почему важно обращаться к <span>профессионалам?</span></h3>
                    <p className={styles.text_box}>Заказывая услугу по строительству дома или бани в компании Я‍-‍STROY, Вы можете быть уверены в высоком качестве выполненых работ. У нас работают квалифицированные мастера, которые обладают многолетним опытом работы в строительстве. Наши мастера успешно справляются с заказами любой сложности. Дом или баня, построенные специалистами Я‍-‍STROY, будет сохраняться в идеальном состоянии на протяжении долгих лет, не нуждаясь в частом ремонте.</p>
                </div>
                <ListWorks/>
                <Scheme/>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default OurServices