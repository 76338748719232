import React, {useEffect} from 'react'
import styles from './Portfolio.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import PortfolioList from '../../components/PortfolioList/PortfolioList'
import Confidence from '../../components/Confidence/Confidence'

const Portfolio = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <BreadCrumbs
                text={'Портфолио'}
            />
            <PortfolioList
                isImageOpen={isImageOpen}
            />
            <Confidence/>
            <Callback
                popupOpen={popupOpen}
            />
        </section>
    )
}

export default Portfolio