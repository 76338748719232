import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/dogovor.svg'
import image_5 from '../../image/Deadlines/funda.svg'
import image_6 from '../../image/Deadlines/dom_1.svg'
import image_7 from '../../image/Deadlines/dom_2.svg'
import image_8 from '../../image/Deadlines/dengi.svg'

const Scheme = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }

    const cardAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .7}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Scheme' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как происходит<span> заказ?</span></motion.h2>
            <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАЯВКА</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>После отправки заявки с вами свяжется наш специалист с целью уточнить ваши пожелания и обговорить все детали.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>предложение</h3>
                            <p className={styles.item_text}>На основании полученной информации мы готовим коммерческое предложениес указанием этапов строительства и их стоимости.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАКЛЮЧАЕМ ДОГОВОР</h3>
                            <p className={styles.item_text}>После согласования всех ключевых условий, сроков и этапов строительства подписываем договор. </p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>монтаж Фундамента</h3>
                            <p className={styles.item_text}>Производим монтаж свайного поля. Предоплата за фундамент составляет 30% от стоимости данного этапа.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>монтаж стен</h3>
                            <p className={styles.item_text}>Производим монтаж стен, перегородок и перекрытий. Предоплата составляет 40% от стоимости данного этапа.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_7} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>завершающий МОНТАЖ</h3>
                            <p className={styles.item_text}>Производим монтаж кровли, дверей, оконных конструкций, и других элементов. Предоплата составляет 30% от стоимости данного этапа.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_8} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Сдача работы</h3>
                            <p className={styles.item_text}>После завершения всех этапов производим окончательный расчет.<span>Мы предоставляем гарантию на строение и проведенные нами работы.</span></p>
                        </motion.li>
                    </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}

export default Scheme
