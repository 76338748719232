import React, {useEffect} from 'react'
import styles from './Team.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import VideoClip from '../../components/VideoClip/VideoClip'
import Confidence from '../../components/Confidence/Confidence'
import TeamMembers from '../../components/TeamMembers/TeamMembers'




const Team = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
                <div className={styles.container}> 
                    <BreadCrumbs
                        text={'О нас'}
                    />
                    <div className={styles.box}>
                        <h2 className={styles.subtitle}><span>Я‍-‍STROY</span> — Ваш надёжный подрядчик!</h2>
                        <p className={styles.text_box}>&#8195;Отлаженная совместная работа профессионального коллектива Я‍-‍STROY способствует стремительному развитию нашей компании. Ведь основа любой компании - это люди, и именно они являются залогом успеха строительства Вашего дома. Наши строители обладают бесценным багажом в виде многолетнего успешного опыта строительства загородных домов и бань. Используя многолетний опыт и современные технологии, мы строим теплые и надежные дома. Каждый сотрудник нашей компании является профессионалом своего дела, благодаря чему все рабочие процессы выполняются успешно и четко.</p>
                    </div>
                    <TeamMembers/>
                    <VideoClip/>
                    <Confidence/>
                    <Callback
                    popupOpen={popupOpen}
                />
                </div>
            </section>
        )
    }
    
    export default Team