import React from 'react'
import styles from './InitialPage.module.scss'
import documents from '../../image/InitialPage/require-title.png'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>Нужен качественный и современный&#160;дом?</p>
                    <h1 className={styles.title}>строительство современных домов и бань в&#160;Москве и Московской области</h1>
                    <div className={styles.box}>
                        <img className={styles.documents} src={documents} alt='документы'/>
                        <p className={styles.subtitle}>Все работы с&#160;гарантией по договору</p>
                    </div>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Профессиональные бригады с опытом более 15 лет</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Заканчиваем строительство обьектов точно в срок</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Осуществляем технический надзор на всех этапах</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <button className={styles.button} type='button' onClick={isOpen}>получить консультацию</button>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage