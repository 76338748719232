import mb1_1 from '../image/DomaFrejm/af-001/img_1.jpg'
import mb1_2 from '../image/DomaFrejm/af-001/img_2.jpg'
import mb1_3 from '../image/DomaFrejm/af-001/img_3.jpg'
import mb1_4 from '../image/DomaFrejm/af-001/img_4.jpg'
import mb1_5 from '../image/DomaFrejm/af-001/img_5.jpg'
import mb1_6 from '../image/DomaFrejm/af-001/img_6.jpg'

import mb2_1 from '../image/DomaFrejm/af-006/img_1.jpg'
import mb2_2 from '../image/DomaFrejm/af-006/img_2.jpg'
import mb2_3 from '../image/DomaFrejm/af-006/img_3.jpg'
import mb2_4 from '../image/DomaFrejm/af-006/img_4.jpg'
import mb2_5 from '../image/DomaFrejm/af-006/img_5.jpg'

import mb3_1 from '../image/DomaFrejm/af-007/img_1.jpg'
import mb3_2 from '../image/DomaFrejm/af-007/img_2.jpg'
import mb3_3 from '../image/DomaFrejm/af-007/img_3.jpg'
import mb3_4 from '../image/DomaFrejm/af-007/img_4.jpg'
import mb3_5 from '../image/DomaFrejm/af-007/img_5.jpg'
import mb3_6 from '../image/DomaFrejm/af-007/img_6.jpg'

import mb4_1 from '../image/DomaFrejm/af-008/img_1.jpg'
import mb4_2 from '../image/DomaFrejm/af-008/img_2.jpg'
import mb4_3 from '../image/DomaFrejm/af-008/img_3.jpg'
import mb4_4 from '../image/DomaFrejm/af-008/img_4.jpg'
import mb4_5 from '../image/DomaFrejm/af-008/img_5.jpg'
import mb4_6 from '../image/DomaFrejm/af-008/img_6.jpg'

import mb5_1 from '../image/DomaFrejm/af-009/img_1.jpg'
import mb5_2 from '../image/DomaFrejm/af-009/img_2.jpg'
import mb5_3 from '../image/DomaFrejm/af-009/img_3.jpg'
import mb5_4 from '../image/DomaFrejm/af-009/img_4.jpg'
import mb5_5 from '../image/DomaFrejm/af-009/img_5.jpg'
import mb5_6 from '../image/DomaFrejm/af-009/img_6.jpg'

import mb6_1 from '../image/DomaFrejm/dbx-002/img_1.jpg'
import mb6_2 from '../image/DomaFrejm/dbx-002/img_2.jpg'
import mb6_3 from '../image/DomaFrejm/dbx-002/img_3.jpg'
import mb6_4 from '../image/DomaFrejm/dbx-002/img_4.jpg'
import mb6_5 from '../image/DomaFrejm/dbx-002/img_5.png'


export const arrAf1 = [{image: mb1_1, title: 'баня'}, {image: mb1_2, title: 'баня'}, {image: mb1_3, title: 'баня'}, {image: mb1_4, title: 'баня'}, {image: mb1_5, title: 'баня'}, {image: mb1_6, title: 'баня'},];

export const arrAf2 = [{image: mb2_1, title: 'баня'}, {image: mb2_2, title: 'баня'}, {image: mb2_3, title: 'баня'}, {image: mb2_4, title: 'баня'}, {image: mb2_5, title: 'баня'},];

export const arrAf3 = [{image: mb3_1, title: 'баня'}, {image: mb3_2, title: 'баня'}, {image: mb3_3, title: 'баня'}, {image: mb3_4, title: 'баня'}, {image: mb3_5, title: 'баня'}, {image: mb3_6, title: 'баня'},];

export const arrAf4 = [{image: mb4_1, title: 'баня'}, {image: mb4_2, title: 'баня'}, {image: mb4_3, title: 'баня'}, {image: mb4_4, title: 'баня'}, {image: mb4_5, title: 'баня'}, {image: mb4_6, title: 'баня'},];

export const arrAf5 = [{image: mb5_1, title: 'баня'}, {image: mb5_2, title: 'баня'}, {image: mb5_3, title: 'баня'}, {image: mb5_4, title: 'баня'}, {image: mb5_5, title: 'баня'}, {image: mb5_6, title: 'баня'},];

export const arrAf6 = [{image: mb6_1, title: 'баня'}, {image: mb6_2, title: 'баня'}, {image: mb6_3, title: 'баня'}, {image: mb6_4, title: 'баня'}, {image: mb6_5, title: 'баня'}, ];