import dk1_001 from '../image/House/dk-001/img_1.jpg'
import dk2_001 from '../image/House/dk-001/img_2.jpg'
import dk3_001 from '../image/House/dk-001/img_3.jpg'
import dk4_001 from '../image/House/dk-001/img_4.jpg'
import dk5_001 from '../image/House/dk-001/img_5.jpg'
import dk6_001 from '../image/House/dk-001/img_6.jpg'
import dk7_001 from '../image/House/dk-001/img_7.jpg'
import dk8_001 from '../image/House/dk-001/img_8.jpg'
import dk9_001 from '../image/House/dk-001/img_9.jpg'
import dk10_001 from '../image/House/dk-001/img_10.jpg'
import dk11_001 from '../image/House/dk-001/img_11.jpg'
import dk12_001 from '../image/House/dk-001/img_12.jpg'
import dk13_001 from '../image/House/dk-001/img_13.jpg'
/////////////////////
import dk1_002 from '../image/House/dk-002/img_1.jpg'
import dk2_002 from '../image/House/dk-002/img_2.jpg'
import dk3_002 from '../image/House/dk-002/img_3.jpg'
import dk4_002 from '../image/House/dk-002/img_4.jpg'
import dk5_002 from '../image/House/dk-002/img_5.jpg'
import dk6_002 from '../image/House/dk-002/img_6.jpg'
import dk7_002 from '../image/House/dk-002/img_7.jpg'
import dk8_002 from '../image/House/dk-002/img_8.jpg'
import dk9_002 from '../image/House/dk-002/img_9.jpg'
import dk10_002 from '../image/House/dk-002/img_10.jpg'
import dk11_002 from '../image/House/dk-002/img_11.jpg'
import dk12_002 from '../image/House/dk-002/img_12.jpg'
import dk13_002 from '../image/House/dk-002/img_13.jpg'

/////////////////////
import dk1_003 from '../image/House/dk-003/img_1.jpg'
import dk2_003 from '../image/House/dk-003/img_2.jpg'
import dk3_003 from '../image/House/dk-003/img_3.jpg'
import dk4_003 from '../image/House/dk-003/img_4.jpg'
import dk5_003 from '../image/House/dk-003/img_5.jpg'
import dk6_003 from '../image/House/dk-003/img_6.jpg'
import dk7_003 from '../image/House/dk-003/img_7.jpg'
import dk8_003 from '../image/House/dk-003/img_8.jpg'
import dk9_003 from '../image/House/dk-003/img_9.jpg'
import dk10_003 from '../image/House/dk-003/img_10.jpg'
import dk11_003 from '../image/House/dk-003/img_11.jpg'
import dk12_003 from '../image/House/dk-003/img_12.jpg'
import dk13_003 from '../image/House/dk-003/img_13.jpg'

/////////////////////
import dk1_005 from '../image/House/dk-005/img_1.jpg'
import dk2_005 from '../image/House/dk-005/img_2.jpg'
import dk3_005 from '../image/House/dk-005/img_3.jpg'
import dk4_005 from '../image/House/dk-005/img_4.jpg'
import dk5_005 from '../image/House/dk-005/img_5.jpg'
import dk6_005 from '../image/House/dk-005/img_6.jpg'
import dk7_005 from '../image/House/dk-005/img_7.jpg'
import dk8_005 from '../image/House/dk-005/img_8.jpg'
import dk9_005 from '../image/House/dk-005/img_9.jpg'
import dk10_005 from '../image/House/dk-005/img_10.jpg'
import dk11_005 from '../image/House/dk-005/img_11.jpg'
import dk12_005 from '../image/House/dk-005/img_12.jpg'
import dk13_005 from '../image/House/dk-005/img_13.jpg'

/////////////////////
import dk1_006 from '../image/House/dk-006/img_1.jpg'
import dk2_006 from '../image/House/dk-006/img_2.jpg'
import dk3_006 from '../image/House/dk-006/img_3.jpg'
import dk4_006 from '../image/House/dk-006/img_4.jpg'
import dk5_006 from '../image/House/dk-006/img_5.jpg'
import dk6_006 from '../image/House/dk-006/img_6.jpg'
import dk7_006 from '../image/House/dk-006/img_7.jpg'
import dk8_006 from '../image/House/dk-006/img_8.jpg'
import dk9_006 from '../image/House/dk-006/img_9.jpg'
import dk10_006 from '../image/House/dk-006/img_10.jpg'
import dk11_006 from '../image/House/dk-006/img_11.jpg'

/////////////////////
import dk1_008 from '../image/House/dk-008/img_1.jpg'
import dk2_008 from '../image/House/dk-008/img_2.jpg'
import dk3_008 from '../image/House/dk-008/img_3.jpg'
import dk4_008 from '../image/House/dk-008/img_4.jpg'
import dk5_008 from '../image/House/dk-008/img_5.jpg'
import dk6_008 from '../image/House/dk-008/img_6.jpg'
import dk7_008 from '../image/House/dk-008/img_7.jpg'
import dk8_008 from '../image/House/dk-008/img_8.jpg'
import dk9_008 from '../image/House/dk-008/img_9.jpg'
import dk10_008 from '../image/House/dk-008/img_10.jpg'
import dk11_008 from '../image/House/dk-008/img_11.jpg'
import dk12_008 from '../image/House/dk-008/img_12.jpg'
import dk13_008 from '../image/House/dk-008/img_13.jpg'

/////////////////////
import dk1_009 from '../image/House/dk-009/img_1.jpg'
import dk2_009 from '../image/House/dk-009/img_2.jpg'
import dk3_009 from '../image/House/dk-009/img_3.jpg'
import dk4_009 from '../image/House/dk-009/img_4.jpg'
import dk5_009 from '../image/House/dk-009/img_5.jpg'
import dk6_009 from '../image/House/dk-009/img_6.jpg'
import dk7_009 from '../image/House/dk-009/img_7.jpg'
import dk8_009 from '../image/House/dk-009/img_8.jpg'
import dk9_009 from '../image/House/dk-009/img_9.jpg'
import dk10_009 from '../image/House/dk-009/img_10.jpg'
import dk11_009 from '../image/House/dk-009/img_11.jpg'
import dk12_009 from '../image/House/dk-009/img_12.jpg'
import dk13_009 from '../image/House/dk-009/img_13.jpg'

/////////////////////
import dk1_010 from '../image/House/dk-010/img_1.jpg'
import dk2_010 from '../image/House/dk-010/img_2.jpg'
import dk3_010 from '../image/House/dk-010/img_3.jpg'
import dk4_010 from '../image/House/dk-010/img_4.jpg'
import dk5_010 from '../image/House/dk-010/img_5.jpg'
import dk6_010 from '../image/House/dk-010/img_6.jpg'
import dk7_010 from '../image/House/dk-010/img_7.jpg'
import dk8_010 from '../image/House/dk-010/img_8.jpg'
import dk9_010 from '../image/House/dk-010/img_9.jpg'
import dk10_010 from '../image/House/dk-010/img_10.jpg'
import dk11_010 from '../image/House/dk-010/img_11.jpg'
import dk12_010 from '../image/House/dk-010/img_12.jpg'
import dk13_010 from '../image/House/dk-010/img_13.jpg'

/////////////////////
import dk1_011 from '../image/House/dk-011/img_1.jpg'
import dk2_011 from '../image/House/dk-011/img_2.jpg'
import dk3_011 from '../image/House/dk-011/img_3.jpg'
import dk4_011 from '../image/House/dk-011/img_4.jpg'
import dk5_011 from '../image/House/dk-011/img_5.jpg'
import dk6_011 from '../image/House/dk-011/img_6.jpg'
import dk7_011 from '../image/House/dk-011/img_7.jpg'
import dk8_011 from '../image/House/dk-011/img_8.jpg'
import dk9_011 from '../image/House/dk-011/img_9.jpg'
import dk10_011 from '../image/House/dk-011/img_10.jpg'
import dk11_011 from '../image/House/dk-011/img_11.jpg'
import dk12_011 from '../image/House/dk-011/img_12.jpg'
import dk13_011 from '../image/House/dk-011/img_13.jpg'

/////////////////////
import dk1_012 from '../image/House/dk-012/img_1.jpg'
import dk2_012 from '../image/House/dk-012/img_2.jpg'
import dk3_012 from '../image/House/dk-012/img_3.jpg'
import dk4_012 from '../image/House/dk-012/img_4.jpg'
import dk5_012 from '../image/House/dk-012/img_5.jpg'
import dk6_012 from '../image/House/dk-012/img_6.jpg'
import dk7_012 from '../image/House/dk-012/img_7.jpg'
import dk8_012 from '../image/House/dk-012/img_8.jpg'
import dk9_012 from '../image/House/dk-012/img_9.jpg'
import dk10_012 from '../image/House/dk-012/img_10.jpg'
import dk11_012 from '../image/House/dk-012/img_11.jpg'
import dk12_012 from '../image/House/dk-012/img_12.jpg'
import dk13_012 from '../image/House/dk-012/img_13.jpg'
/////////////////////
import dk1_013 from '../image/House/dk-013/img_1.jpg'
import dk2_013 from '../image/House/dk-013/img_2.jpg'
import dk3_013 from '../image/House/dk-013/img_3.jpg'
import dk4_013 from '../image/House/dk-013/img_4.jpg'
import dk5_013 from '../image/House/dk-013/img_5.jpg'
import dk6_013 from '../image/House/dk-013/img_6.jpg'
import dk7_013 from '../image/House/dk-013/img_7.jpg'
import dk8_013 from '../image/House/dk-013/img_8.jpg'
import dk9_013 from '../image/House/dk-013/img_9.jpg'
import dk10_013 from '../image/House/dk-013/img_10.jpg'
import dk11_013 from '../image/House/dk-013/img_11.jpg'
import dk12_013 from '../image/House/dk-013/img_12.jpg'
import dk13_013 from '../image/House/dk-013/img_13.jpg'
/////////////////////
import dk1_014 from '../image/House/dk-014/img_1.jpg'
import dk2_014 from '../image/House/dk-014/img_2.jpg'
import dk3_014 from '../image/House/dk-014/img_3.jpg'
import dk4_014 from '../image/House/dk-014/img_4.jpg'
import dk5_014 from '../image/House/dk-014/img_5.jpg'
import dk6_014 from '../image/House/dk-014/img_6.jpg'
import dk7_014 from '../image/House/dk-014/img_7.jpg'
import dk8_014 from '../image/House/dk-014/img_8.jpg'
import dk9_014 from '../image/House/dk-014/img_9.jpg'
import dk10_014 from '../image/House/dk-014/img_10.jpg'
import dk11_014 from '../image/House/dk-014/img_11.jpg'
import dk12_014 from '../image/House/dk-014/img_12.jpg'
import dk13_014 from '../image/House/dk-014/img_13.jpg'
/////////////////////
import dk1_015 from '../image/House/dk-015/img_1.jpg'
import dk2_015 from '../image/House/dk-015/img_2.jpg'
import dk3_015 from '../image/House/dk-015/img_3.jpg'
import dk4_015 from '../image/House/dk-015/img_4.jpg'
import dk5_015 from '../image/House/dk-015/img_5.jpg'
import dk6_015 from '../image/House/dk-015/img_6.jpg'
import dk7_015 from '../image/House/dk-015/img_7.jpg'
import dk8_015 from '../image/House/dk-015/img_8.jpg'
import dk9_015 from '../image/House/dk-015/img_9.jpg'
import dk10_015 from '../image/House/dk-015/img_10.jpg'
import dk11_015 from '../image/House/dk-015/img_11.jpg'
import dk12_015 from '../image/House/dk-015/img_12.jpg'
import dk13_015 from '../image/House/dk-015/img_13.jpg'

/////////////////////
import dk1_016 from '../image/House/dk-016/img_1.jpg'
import dk2_016 from '../image/House/dk-016/img_2.jpg'
import dk3_016 from '../image/House/dk-016/img_3.jpg'
import dk4_016 from '../image/House/dk-016/img_4.jpg'
import dk5_016 from '../image/House/dk-016/img_5.jpg'
import dk6_016 from '../image/House/dk-016/img_6.jpg'
import dk7_016 from '../image/House/dk-016/img_7.jpg'
import dk8_016 from '../image/House/dk-016/img_8.jpg'
import dk9_016 from '../image/House/dk-016/img_9.jpg'
import dk10_016 from '../image/House/dk-016/img_10.jpg'
import dk11_016 from '../image/House/dk-016/img_11.jpg'
import dk12_016 from '../image/House/dk-016/img_12.jpg'
import dk13_016 from '../image/House/dk-016/img_13.jpg'
/////////////////////
import dk1_017 from '../image/House/dk-017/img_1.jpg'
import dk2_017 from '../image/House/dk-017/img_2.jpg'
import dk3_017 from '../image/House/dk-017/img_3.jpg'
import dk4_017 from '../image/House/dk-017/img_4.jpg'
import dk5_017 from '../image/House/dk-017/img_5.jpg'
import dk6_017 from '../image/House/dk-017/img_6.jpg'
import dk7_017 from '../image/House/dk-017/img_7.jpg'
import dk8_017 from '../image/House/dk-017/img_8.jpg'
import dk9_017 from '../image/House/dk-017/img_9.jpg'
import dk10_017 from '../image/House/dk-017/img_10.jpg'
import dk11_017 from '../image/House/dk-017/img_11.jpg'
import dk12_017 from '../image/House/dk-017/img_12.jpg'
import dk13_017 from '../image/House/dk-017/img_13.jpg'
/////////////////////
import dk1_018 from '../image/House/dk-018/img_1.jpg'
import dk2_018 from '../image/House/dk-018/img_2.jpg'
import dk3_018 from '../image/House/dk-018/img_3.jpg'
import dk4_018 from '../image/House/dk-018/img_4.jpg'
import dk5_018 from '../image/House/dk-018/img_5.jpg'
import dk6_018 from '../image/House/dk-018/img_6.jpg'
import dk7_018 from '../image/House/dk-018/img_7.jpg'
import dk8_018 from '../image/House/dk-018/img_8.jpg'
import dk9_018 from '../image/House/dk-018/img_9.jpg'
import dk10_018 from '../image/House/dk-018/img_10.jpg'
import dk11_018 from '../image/House/dk-018/img_11.jpg'
import dk12_018 from '../image/House/dk-018/img_12.jpg'
import dk13_018 from '../image/House/dk-018/img_13.jpg'
/////////////////////
import dk1_019 from '../image/House/dk-019/img_1.jpg'
import dk2_019 from '../image/House/dk-019/img_2.jpg'
import dk3_019 from '../image/House/dk-019/img_3.jpg'
import dk4_019 from '../image/House/dk-019/img_4.jpg'
import dk5_019 from '../image/House/dk-019/img_5.jpg'
import dk6_019 from '../image/House/dk-019/img_6.jpg'
import dk7_019 from '../image/House/dk-019/img_7.jpg'
import dk8_019 from '../image/House/dk-019/img_8.jpg'
import dk9_019 from '../image/House/dk-019/img_9.jpg'
import dk10_019 from '../image/House/dk-019/img_10.jpg'
import dk11_019 from '../image/House/dk-019/img_11.jpg'
import dk12_019 from '../image/House/dk-019/img_12.jpg'
import dk13_019 from '../image/House/dk-019/img_13.jpg'
/////////////////////
import dk1_020 from '../image/House/dk-020/img_1.jpg'
import dk2_020 from '../image/House/dk-020/img_2.jpg'
import dk3_020 from '../image/House/dk-020/img_3.jpg'
import dk4_020 from '../image/House/dk-020/img_4.jpg'
import dk5_020 from '../image/House/dk-020/img_5.jpg'
import dk6_020 from '../image/House/dk-020/img_6.jpg'
import dk7_020 from '../image/House/dk-020/img_7.jpg'
import dk8_020 from '../image/House/dk-020/img_8.jpg'
import dk9_020 from '../image/House/dk-020/img_9.jpg'
import dk10_020 from '../image/House/dk-020/img_10.jpg'
import dk11_020 from '../image/House/dk-020/img_11.jpg'
import dk12_020 from '../image/House/dk-020/img_12.jpg'
import dk13_020 from '../image/House/dk-020/img_13.jpg'
/////////////////////
import dk1_022 from '../image/House/dk-022/img_1.jpg'
import dk2_022 from '../image/House/dk-022/img_2.jpg'
import dk3_022 from '../image/House/dk-022/img_3.jpg'
import dk4_022 from '../image/House/dk-022/img_4.jpg'
import dk5_022 from '../image/House/dk-022/img_5.jpg'
import dk6_022 from '../image/House/dk-022/img_6.jpg'
import dk7_022 from '../image/House/dk-022/img_7.jpg'
import dk8_022 from '../image/House/dk-022/img_8.jpg'
import dk9_022 from '../image/House/dk-022/img_9.jpg'
import dk10_022 from '../image/House/dk-022/img_10.jpg'
import dk11_022 from '../image/House/dk-022/img_11.jpg'
import dk12_022 from '../image/House/dk-022/img_12.jpg'
import dk13_022 from '../image/House/dk-022/img_13.jpg'

/////////////////////
import dk1_023 from '../image/House/dk-023/img_1.jpg'
import dk2_023 from '../image/House/dk-023/img_2.jpg'
import dk3_023 from '../image/House/dk-023/img_3.jpg'
import dk4_023 from '../image/House/dk-023/img_4.jpg'
import dk5_023 from '../image/House/dk-023/img_5.jpg'
import dk6_023 from '../image/House/dk-023/img_6.jpg'
import dk7_023 from '../image/House/dk-023/img_7.jpg'
import dk8_023 from '../image/House/dk-023/img_8.jpg'
import dk9_023 from '../image/House/dk-023/img_9.jpg'
import dk10_023 from '../image/House/dk-023/img_10.jpg'
import dk11_023 from '../image/House/dk-023/img_11.jpg'
import dk12_023 from '../image/House/dk-023/img_12.jpg'
import dk13_023 from '../image/House/dk-023/img_13.jpg'

/////////////////////
import dk1_024 from '../image/House/dk-024/img_1.jpg'
import dk2_024 from '../image/House/dk-024/img_2.jpg'
import dk3_024 from '../image/House/dk-024/img_3.jpg'
import dk4_024 from '../image/House/dk-024/img_4.jpg'
import dk5_024 from '../image/House/dk-024/img_5.jpg'
import dk6_024 from '../image/House/dk-024/img_6.jpg'
import dk7_024 from '../image/House/dk-024/img_7.jpg'
import dk8_024 from '../image/House/dk-024/img_8.jpg'
import dk9_024 from '../image/House/dk-024/img_9.jpg'
import dk10_024 from '../image/House/dk-024/img_10.jpg'
import dk11_024 from '../image/House/dk-024/img_11.jpg'
import dk12_024 from '../image/House/dk-024/img_12.jpg'
import dk13_024 from '../image/House/dk-024/img_13.jpg'

/////////////////////
import dk1_025 from '../image/House/dk-025/img_1.jpg'
import dk2_025 from '../image/House/dk-025/img_2.jpg'
import dk3_025 from '../image/House/dk-025/img_3.jpg'
import dk4_025 from '../image/House/dk-025/img_4.jpg'
import dk5_025 from '../image/House/dk-025/img_5.jpg'
import dk6_025 from '../image/House/dk-025/img_6.jpg'
import dk7_025 from '../image/House/dk-025/img_7.jpg'
import dk8_025 from '../image/House/dk-025/img_8.jpg'
import dk9_025 from '../image/House/dk-025/img_9.jpg'
import dk10_025 from '../image/House/dk-025/img_10.jpg'
import dk11_025 from '../image/House/dk-025/img_11.jpg'
import dk12_025 from '../image/House/dk-025/img_12.jpg'
import dk13_025 from '../image/House/dk-025/img_13.jpg'

/////////////////////
import dk1_026 from '../image/House/dk-026/img_1.jpg'
import dk2_026 from '../image/House/dk-026/img_2.jpg'
import dk3_026 from '../image/House/dk-026/img_3.jpg'
import dk4_026 from '../image/House/dk-026/img_4.jpg'
import dk5_026 from '../image/House/dk-026/img_5.jpg'
import dk6_026 from '../image/House/dk-026/img_6.jpg'
import dk7_026 from '../image/House/dk-026/img_7.jpg'
import dk8_026 from '../image/House/dk-026/img_8.jpg'
import dk9_026 from '../image/House/dk-026/img_9.jpg'
import dk10_026 from '../image/House/dk-026/img_10.jpg'
import dk11_026 from '../image/House/dk-026/img_11.jpg'
import dk12_026 from '../image/House/dk-026/img_12.jpg'
import dk13_026 from '../image/House/dk-026/img_13.jpg'

/////////////////////
import dk1_029 from '../image/House/dk-029/img_1.jpg'
import dk2_029 from '../image/House/dk-029/img_2.jpg'
import dk3_029 from '../image/House/dk-029/img_3.jpg'
import dk4_029 from '../image/House/dk-029/img_4.jpg'
import dk5_029 from '../image/House/dk-029/img_5.jpg'
import dk6_029 from '../image/House/dk-029/img_6.jpg'
import dk7_029 from '../image/House/dk-029/img_7.jpg'
import dk8_029 from '../image/House/dk-029/img_8.jpg'
import dk9_029 from '../image/House/dk-029/img_9.jpg'
import dk10_029 from '../image/House/dk-029/img_10.jpg'
import dk11_029 from '../image/House/dk-029/img_11.jpg'
import dk12_029 from '../image/House/dk-029/img_12.jpg'
import dk13_029 from '../image/House/dk-029/img_13.jpg'

/////////////////////
import dk1_030 from '../image/House/dk-030/img_1.jpg'
import dk2_030 from '../image/House/dk-030/img_2.jpg'
import dk3_030 from '../image/House/dk-030/img_3.jpg'
import dk4_030 from '../image/House/dk-030/img_4.jpg'
import dk5_030 from '../image/House/dk-030/img_5.jpg'
import dk6_030 from '../image/House/dk-030/img_6.jpg'
import dk7_030 from '../image/House/dk-030/img_7.jpg'
import dk8_030 from '../image/House/dk-030/img_8.jpg'
import dk9_030 from '../image/House/dk-030/img_9.jpg'
import dk10_030 from '../image/House/dk-030/img_10.jpg'
import dk11_030 from '../image/House/dk-030/img_11.jpg'
import dk12_030 from '../image/House/dk-030/img_12.jpg'
import dk13_030 from '../image/House/dk-030/img_13.jpg'

/////////////////////
import dk1_031 from '../image/House/dk-031/img_1.jpg'
import dk2_031 from '../image/House/dk-031/img_2.jpg'
import dk3_031 from '../image/House/dk-031/img_3.jpg'
import dk4_031 from '../image/House/dk-031/img_4.jpg'
import dk5_031 from '../image/House/dk-031/img_5.jpg'
import dk6_031 from '../image/House/dk-031/img_6.jpg'
import dk7_031 from '../image/House/dk-031/img_7.jpg'
import dk8_031 from '../image/House/dk-031/img_8.jpg'
import dk9_031 from '../image/House/dk-031/img_9.jpg'
import dk10_031 from '../image/House/dk-031/img_10.jpg'
import dk11_031 from '../image/House/dk-031/img_11.jpg'
import dk12_031 from '../image/House/dk-031/img_12.jpg'
import dk13_031 from '../image/House/dk-031/img_13.jpg'

/////////////////////
import dk1_035 from '../image/House/dk-035/img_1.jpg'
import dk2_035 from '../image/House/dk-035/img_2.jpg'
import dk3_035 from '../image/House/dk-035/img_3.jpg'
import dk4_035 from '../image/House/dk-035/img_4.jpg'
import dk5_035 from '../image/House/dk-035/img_5.jpg'
import dk6_035 from '../image/House/dk-035/img_6.jpg'
import dk7_035 from '../image/House/dk-035/img_7.jpg'
import dk8_035 from '../image/House/dk-035/img_8.jpg'
import dk9_035 from '../image/House/dk-035/img_9.jpg'
import dk10_035 from '../image/House/dk-035/img_10.jpg'
import dk11_035 from '../image/House/dk-035/img_11.jpg'
import dk12_035 from '../image/House/dk-035/img_12.jpg'
import dk13_035 from '../image/House/dk-035/img_13.jpg'

/////////////////////
import dk1_036 from '../image/House/dk-036/img_1.jpg'
import dk2_036 from '../image/House/dk-036/img_2.jpg'
import dk3_036 from '../image/House/dk-036/img_3.jpg'
import dk4_036 from '../image/House/dk-036/img_4.jpg'
import dk5_036 from '../image/House/dk-036/img_5.jpg'
import dk6_036 from '../image/House/dk-036/img_6.jpg'
import dk7_036 from '../image/House/dk-036/img_7.jpg'
import dk8_036 from '../image/House/dk-036/img_8.jpg'
import dk9_036 from '../image/House/dk-036/img_9.jpg'
import dk10_036 from '../image/House/dk-036/img_10.jpg'
import dk11_036 from '../image/House/dk-036/img_11.jpg'
import dk12_036 from '../image/House/dk-036/img_12.jpg'
import dk13_036 from '../image/House/dk-036/img_13.jpg'

/////////////////////
import dk1_038 from '../image/House/dk-038/img_1.jpg'
import dk2_038 from '../image/House/dk-038/img_2.jpg'
import dk3_038 from '../image/House/dk-038/img_3.jpg'
import dk4_038 from '../image/House/dk-038/img_4.jpg'
import dk5_038 from '../image/House/dk-038/img_5.jpg'
import dk6_038 from '../image/House/dk-038/img_6.jpg'
import dk7_038 from '../image/House/dk-038/img_7.jpg'
import dk8_038 from '../image/House/dk-038/img_8.jpg'
import dk9_038 from '../image/House/dk-038/img_9.jpg'
import dk10_038 from '../image/House/dk-038/img_10.jpg'
import dk11_038 from '../image/House/dk-038/img_11.jpg'
import dk12_038 from '../image/House/dk-038/img_12.jpg'
import dk13_038 from '../image/House/dk-038/img_13.jpg'

/////////////////////
import dk1_039 from '../image/House/dk-039/img_1.jpg'
import dk2_039 from '../image/House/dk-039/img_2.jpg'
import dk3_039 from '../image/House/dk-039/img_3.jpg'
import dk4_039 from '../image/House/dk-039/img_4.jpg'
import dk5_039 from '../image/House/dk-039/img_5.jpg'
import dk6_039 from '../image/House/dk-039/img_6.jpg'
import dk7_039 from '../image/House/dk-039/img_7.jpg'
import dk8_039 from '../image/House/dk-039/img_8.jpg'
import dk9_039 from '../image/House/dk-039/img_9.jpg'
import dk10_039 from '../image/House/dk-039/img_10.jpg'
import dk11_039 from '../image/House/dk-039/img_11.jpg'
import dk12_039 from '../image/House/dk-039/img_12.jpg'
import dk13_039 from '../image/House/dk-039/img_13.jpg'

/////////////////////
import dk1_040 from '../image/House/dk-040/img_1.jpg'
import dk2_040 from '../image/House/dk-040/img_2.jpg'
import dk3_040 from '../image/House/dk-040/img_3.jpg'
import dk4_040 from '../image/House/dk-040/img_4.jpg'
import dk5_040 from '../image/House/dk-040/img_5.jpg'
import dk6_040 from '../image/House/dk-040/img_6.jpg'
import dk7_040 from '../image/House/dk-040/img_7.jpg'
import dk8_040 from '../image/House/dk-040/img_8.jpg'
import dk9_040 from '../image/House/dk-040/img_9.jpg'
import dk10_040 from '../image/House/dk-040/img_10.jpg'
import dk11_040 from '../image/House/dk-040/img_11.jpg'
import dk12_040 from '../image/House/dk-040/img_12.jpg'
import dk13_040 from '../image/House/dk-040/img_13.jpg'

/////////////////////
import dk1_041 from '../image/House/dk-041/img_1.jpg'
import dk2_041 from '../image/House/dk-041/img_2.jpg'
import dk3_041 from '../image/House/dk-041/img_3.jpg'
import dk4_041 from '../image/House/dk-041/img_4.jpg'
import dk5_041 from '../image/House/dk-041/img_5.jpg'
import dk6_041 from '../image/House/dk-041/img_6.jpg'
import dk7_041 from '../image/House/dk-041/img_7.jpg'
import dk8_041 from '../image/House/dk-041/img_8.jpg'
import dk9_041 from '../image/House/dk-041/img_9.jpg'
import dk10_041 from '../image/House/dk-041/img_10.jpg'
import dk11_041 from '../image/House/dk-041/img_11.jpg'
import dk12_041 from '../image/House/dk-041/img_12.jpg'
import dk13_041 from '../image/House/dk-041/img_13.jpg'

/////////////////////
import dk1_042 from '../image/House/dk-042/img_1.jpg'
import dk2_042 from '../image/House/dk-042/img_2.jpg'
import dk3_042 from '../image/House/dk-042/img_3.jpg'
import dk4_042 from '../image/House/dk-042/img_4.jpg'
import dk5_042 from '../image/House/dk-042/img_5.jpg'
import dk6_042 from '../image/House/dk-042/img_6.jpg'
import dk7_042 from '../image/House/dk-042/img_7.jpg'
import dk8_042 from '../image/House/dk-042/img_8.jpg'
import dk9_042 from '../image/House/dk-042/img_9.jpg'
import dk10_042 from '../image/House/dk-042/img_10.jpg'
import dk11_042 from '../image/House/dk-042/img_11.jpg'
import dk12_042 from '../image/House/dk-042/img_12.jpg'
import dk13_042 from '../image/House/dk-042/img_13.jpg'

/////////////////////
import dk1_044 from '../image/House/dk-044/img_1.jpg'
import dk2_044 from '../image/House/dk-044/img_2.jpg'
import dk3_044 from '../image/House/dk-044/img_3.jpg'
import dk4_044 from '../image/House/dk-044/img_4.jpg'
import dk5_044 from '../image/House/dk-044/img_5.jpg'
import dk6_044 from '../image/House/dk-044/img_6.jpg'
import dk7_044 from '../image/House/dk-044/img_7.jpg'
import dk8_044 from '../image/House/dk-044/img_8.jpg'
import dk9_044 from '../image/House/dk-044/img_9.jpg'
import dk10_044 from '../image/House/dk-044/img_10.jpg'
import dk11_044 from '../image/House/dk-044/img_11.jpg'
import dk12_044 from '../image/House/dk-044/img_12.jpg'
import dk13_044 from '../image/House/dk-044/img_13.jpg'

/////////////////////
import dk1_045 from '../image/House/dk-045/img_1.jpg'
import dk2_045 from '../image/House/dk-045/img_2.jpg'
import dk3_045 from '../image/House/dk-045/img_3.jpg'
import dk4_045 from '../image/House/dk-045/img_4.jpg'
import dk5_045 from '../image/House/dk-045/img_5.jpg'
import dk6_045 from '../image/House/dk-045/img_6.jpg'
import dk7_045 from '../image/House/dk-045/img_7.jpg'
import dk8_045 from '../image/House/dk-045/img_8.jpg'
import dk9_045 from '../image/House/dk-045/img_9.jpg'
import dk10_045 from '../image/House/dk-045/img_10.jpg'
import dk11_045 from '../image/House/dk-045/img_11.jpg'
import dk12_045 from '../image/House/dk-045/img_12.jpg'
import dk13_045 from '../image/House/dk-045/img_13.jpg'

/////////////////////
import dk1_046 from '../image/House/dk-046/img_1.jpg'
import dk2_046 from '../image/House/dk-046/img_2.jpg'
import dk3_046 from '../image/House/dk-046/img_3.jpg'
import dk4_046 from '../image/House/dk-046/img_4.jpg'
import dk5_046 from '../image/House/dk-046/img_5.jpg'
import dk6_046 from '../image/House/dk-046/img_6.jpg'
import dk7_046 from '../image/House/dk-046/img_7.jpg'
import dk8_046 from '../image/House/dk-046/img_8.jpg'
import dk9_046 from '../image/House/dk-046/img_9.jpg'
import dk10_046 from '../image/House/dk-046/img_10.jpg'
import dk11_046 from '../image/House/dk-046/img_11.jpg'
import dk12_046 from '../image/House/dk-046/img_12.jpg'
import dk13_046 from '../image/House/dk-046/img_13.jpg'

/////////////////////
import dk1_047 from '../image/House/dk-047/img_1.jpg'
import dk2_047 from '../image/House/dk-047/img_2.jpg'
import dk3_047 from '../image/House/dk-047/img_3.jpg'
import dk4_047 from '../image/House/dk-047/img_4.jpg'
import dk5_047 from '../image/House/dk-047/img_5.jpg'
import dk6_047 from '../image/House/dk-047/img_6.jpg'
import dk7_047 from '../image/House/dk-047/img_7.jpg'
import dk8_047 from '../image/House/dk-047/img_8.jpg'
import dk9_047 from '../image/House/dk-047/img_9.jpg'
import dk10_047 from '../image/House/dk-047/img_10.jpg'
import dk11_047 from '../image/House/dk-047/img_11.jpg'
import dk12_047 from '../image/House/dk-047/img_12.jpg'
import dk13_047 from '../image/House/dk-047/img_13.jpg'

/////////////////////
import dk1_049 from '../image/House/dk-049/img_1.jpg'
import dk2_049 from '../image/House/dk-049/img_2.jpg'
import dk3_049 from '../image/House/dk-049/img_3.jpg'
import dk4_049 from '../image/House/dk-049/img_4.jpg'
import dk5_049 from '../image/House/dk-049/img_5.jpg'
import dk6_049 from '../image/House/dk-049/img_6.jpg'
import dk7_049 from '../image/House/dk-049/img_7.jpg'
import dk8_049 from '../image/House/dk-049/img_8.jpg'
import dk9_049 from '../image/House/dk-049/img_9.jpg'
import dk10_049 from '../image/House/dk-049/img_10.jpg'
import dk11_049 from '../image/House/dk-049/img_11.jpg'
import dk12_049 from '../image/House/dk-049/img_12.jpg'
import dk13_049 from '../image/House/dk-049/img_13.jpg'

/////////////////////
import dk1_050 from '../image/House/dk-050/img_1.jpg'
import dk2_050 from '../image/House/dk-050/img_2.jpg'
import dk3_050 from '../image/House/dk-050/img_3.jpg'
import dk4_050 from '../image/House/dk-050/img_4.jpg'
import dk5_050 from '../image/House/dk-050/img_5.jpg'
import dk6_050 from '../image/House/dk-050/img_6.jpg'
import dk7_050 from '../image/House/dk-050/img_7.jpg'
import dk8_050 from '../image/House/dk-050/img_8.jpg'
import dk9_050 from '../image/House/dk-050/img_9.jpg'
import dk10_050 from '../image/House/dk-050/img_10.jpg'
import dk11_050 from '../image/House/dk-050/img_11.jpg'
import dk12_050 from '../image/House/dk-050/img_12.jpg'
import dk13_050 from '../image/House/dk-050/img_13.jpg'

/////////////////////
import dk1_302 from '../image/House/dk-302/img_1.jpg'
import dk2_302 from '../image/House/dk-302/img_2.jpg'
import dk3_302 from '../image/House/dk-302/img_3.jpg'
import dk4_302 from '../image/House/dk-302/img_4.jpg'
import dk5_302 from '../image/House/dk-302/img_5.jpg'
import dk6_302 from '../image/House/dk-302/img_6.jpg'
import dk7_302 from '../image/House/dk-302/img_7.jpg'
import dk8_302 from '../image/House/dk-302/img_8.jpg'
import dk9_302 from '../image/House/dk-302/img_9.jpg'
import dk10_302 from '../image/House/dk-302/img_10.jpg'
import dk11_302 from '../image/House/dk-302/img_11.jpg'
import dk12_302 from '../image/House/dk-302/img_12.jpg'
import dk13_302 from '../image/House/dk-302/img_13.jpg'
import dk14_302 from '../image/House/dk-302/img_14.jpg'

/////////////////////
import dk1_303 from '../image/House/dk-303/img_1.jpg'
import dk2_303 from '../image/House/dk-303/img_2.jpg'
import dk3_303 from '../image/House/dk-303/img_3.jpg'
import dk4_303 from '../image/House/dk-303/img_4.jpg'
import dk5_303 from '../image/House/dk-303/img_5.jpg'
import dk6_303 from '../image/House/dk-303/img_6.jpg'
import dk7_303 from '../image/House/dk-303/img_7.jpg'
import dk8_303 from '../image/House/dk-303/img_8.jpg'
import dk9_303 from '../image/House/dk-303/img_9.jpg'
import dk10_303 from '../image/House/dk-303/img_10.jpg'
import dk11_303 from '../image/House/dk-303/img_11.jpg'
import dk12_303 from '../image/House/dk-303/img_12.jpg'
import dk13_303 from '../image/House/dk-303/img_13.jpg'
import dk14_303 from '../image/House/dk-303/img_14.jpg'

/////////////////////
import dk1_304 from '../image/House/dk-304/img_1.jpg'
import dk2_304 from '../image/House/dk-304/img_2.jpg'
import dk3_304 from '../image/House/dk-304/img_3.jpg'
import dk4_304 from '../image/House/dk-304/img_4.jpg'
import dk5_304 from '../image/House/dk-304/img_5.jpg'
import dk6_304 from '../image/House/dk-304/img_6.jpg'
import dk7_304 from '../image/House/dk-304/img_7.jpg'
import dk8_304 from '../image/House/dk-304/img_8.jpg'
import dk9_304 from '../image/House/dk-304/img_9.jpg'
import dk10_304 from '../image/House/dk-304/img_10.jpg'
import dk11_304 from '../image/House/dk-304/img_11.jpg'
import dk12_304 from '../image/House/dk-304/img_12.jpg'
import dk13_304 from '../image/House/dk-304/img_13.jpg'
import dk14_304 from '../image/House/dk-304/img_14.jpg'

/////////////////////
import dk1_305 from '../image/House/dk-305/img_1.jpg'
import dk2_305 from '../image/House/dk-305/img_2.jpg'
import dk3_305 from '../image/House/dk-305/img_3.jpg'
import dk4_305 from '../image/House/dk-305/img_4.jpg'
import dk5_305 from '../image/House/dk-305/img_5.jpg'
import dk6_305 from '../image/House/dk-305/img_6.jpg'
import dk7_305 from '../image/House/dk-305/img_7.jpg'
import dk8_305 from '../image/House/dk-305/img_8.jpg'
import dk9_305 from '../image/House/dk-305/img_9.jpg'
import dk10_305 from '../image/House/dk-305/img_10.jpg'
import dk11_305 from '../image/House/dk-305/img_11.jpg'
import dk12_305 from '../image/House/dk-305/img_12.jpg'
import dk13_305 from '../image/House/dk-305/img_13.jpg'
import dk14_305 from '../image/House/dk-305/img_14.jpg'

/////////////////////
import dk1_307 from '../image/House/dk-307/img_1.jpg'
import dk2_307 from '../image/House/dk-307/img_2.jpg'
import dk3_307 from '../image/House/dk-307/img_3.jpg'
import dk4_307 from '../image/House/dk-307/img_4.jpg'
import dk5_307 from '../image/House/dk-307/img_5.jpg'
import dk6_307 from '../image/House/dk-307/img_6.jpg'
import dk7_307 from '../image/House/dk-307/img_7.jpg'
import dk8_307 from '../image/House/dk-307/img_8.jpg'
import dk9_307 from '../image/House/dk-307/img_9.jpg'
import dk10_307 from '../image/House/dk-307/img_10.jpg'
import dk11_307 from '../image/House/dk-307/img_11.jpg'
import dk12_307 from '../image/House/dk-307/img_12.jpg'
import dk13_307 from '../image/House/dk-307/img_13.jpg'
import dk14_307 from '../image/House/dk-307/img_14.jpg'

/////////////////////
import dk1_310 from '../image/House/dk-310/img_1.jpg'
import dk2_310 from '../image/House/dk-310/img_2.jpg'
import dk3_310 from '../image/House/dk-310/img_3.jpg'
import dk4_310 from '../image/House/dk-310/img_4.jpg'
import dk5_310 from '../image/House/dk-310/img_5.jpg'
import dk6_310 from '../image/House/dk-310/img_6.jpg'
import dk7_310 from '../image/House/dk-310/img_7.jpg'
import dk8_310 from '../image/House/dk-310/img_8.jpg'
import dk9_310 from '../image/House/dk-310/img_9.jpg'
import dk10_310 from '../image/House/dk-310/img_10.jpg'
import dk11_310 from '../image/House/dk-310/img_11.jpg'
import dk12_310 from '../image/House/dk-310/img_12.jpg'
import dk13_310 from '../image/House/dk-310/img_13.jpg'
import dk14_310 from '../image/House/dk-310/img_14.jpg'

/////////////////////
import dk1_316 from '../image/House/dk-316/img_1.jpg'
import dk2_316 from '../image/House/dk-316/img_2.jpg'
import dk3_316 from '../image/House/dk-316/img_3.jpg'
import dk4_316 from '../image/House/dk-316/img_4.jpg'
import dk5_316 from '../image/House/dk-316/img_5.jpg'
import dk6_316 from '../image/House/dk-316/img_6.jpg'
import dk7_316 from '../image/House/dk-316/img_7.jpg'
import dk8_316 from '../image/House/dk-316/img_8.jpg'
import dk9_316 from '../image/House/dk-316/img_9.jpg'
import dk10_316 from '../image/House/dk-316/img_10.jpg'
import dk11_316 from '../image/House/dk-316/img_11.jpg'
import dk12_316 from '../image/House/dk-316/img_12.jpg'
import dk13_316 from '../image/House/dk-316/img_13.jpg'
import dk14_316 from '../image/House/dk-316/img_14.jpg'

/////////////////////
import dk1_319 from '../image/House/dk-319/img_1.jpg'
import dk2_319 from '../image/House/dk-319/img_2.jpg'
import dk3_319 from '../image/House/dk-319/img_3.jpg'
import dk4_319 from '../image/House/dk-319/img_4.jpg'
import dk5_319 from '../image/House/dk-319/img_5.jpg'
import dk6_319 from '../image/House/dk-319/img_6.jpg'
import dk7_319 from '../image/House/dk-319/img_7.jpg'
import dk8_319 from '../image/House/dk-319/img_8.jpg'
import dk9_319 from '../image/House/dk-319/img_9.jpg'
import dk10_319 from '../image/House/dk-319/img_10.jpg'
import dk11_319 from '../image/House/dk-319/img_11.jpg'
import dk12_319 from '../image/House/dk-319/img_12.jpg'
import dk13_319 from '../image/House/dk-319/img_13.jpg'
import dk14_319 from '../image/House/dk-319/img_14.jpg'

/////////////////////
import dk1_320 from '../image/House/dk-320/img_1.jpg'
import dk2_320 from '../image/House/dk-320/img_2.jpg'
import dk3_320 from '../image/House/dk-320/img_3.jpg'
import dk4_320 from '../image/House/dk-320/img_4.jpg'
import dk5_320 from '../image/House/dk-320/img_5.jpg'
import dk6_320 from '../image/House/dk-320/img_6.jpg'
import dk7_320 from '../image/House/dk-320/img_7.jpg'
import dk8_320 from '../image/House/dk-320/img_8.jpg'
import dk9_320 from '../image/House/dk-320/img_9.jpg'
import dk10_320 from '../image/House/dk-320/img_10.jpg'
import dk11_320 from '../image/House/dk-320/img_11.jpg'
import dk12_320 from '../image/House/dk-320/img_12.jpg'
import dk13_320 from '../image/House/dk-320/img_13.jpg'
import dk14_320 from '../image/House/dk-320/img_14.jpg'

/////////////////////
import dk1_602 from '../image/House/dk-602/img_1.jpg'
import dk2_602 from '../image/House/dk-602/img_2.jpg'
import dk3_602 from '../image/House/dk-602/img_3.jpg'
import dk4_602 from '../image/House/dk-602/img_4.jpg'
import dk5_602 from '../image/House/dk-602/img_5.jpg'
import dk6_602 from '../image/House/dk-602/img_6.jpg'
import dk7_602 from '../image/House/dk-602/img_7.jpg'
import dk8_602 from '../image/House/dk-602/img_8.jpg'
import dk9_602 from '../image/House/dk-602/img_9.jpg'
import dk10_602 from '../image/House/dk-602/img_10.jpg'
import dk11_602 from '../image/House/dk-602/img_11.jpg'
import dk12_602 from '../image/House/dk-602/img_12.jpg'
import dk13_602 from '../image/House/dk-602/img_13.jpg'
import dk14_602 from '../image/House/dk-602/img_14.jpg'

/////////////////////
import dk1_606 from '../image/House/dk-606/img_1.jpg'
import dk2_606 from '../image/House/dk-606/img_2.jpg'
import dk3_606 from '../image/House/dk-606/img_3.jpg'
import dk4_606 from '../image/House/dk-606/img_4.jpg'
import dk5_606 from '../image/House/dk-606/img_5.jpg'
import dk6_606 from '../image/House/dk-606/img_6.jpg'
import dk7_606 from '../image/House/dk-606/img_7.jpg'
import dk8_606 from '../image/House/dk-606/img_8.jpg'
import dk9_606 from '../image/House/dk-606/img_9.jpg'
import dk10_606 from '../image/House/dk-606/img_10.jpg'
import dk11_606 from '../image/House/dk-606/img_11.jpg'
import dk12_606 from '../image/House/dk-606/img_12.jpg'
import dk13_606 from '../image/House/dk-606/img_13.jpg'
import dk14_606 from '../image/House/dk-606/img_14.jpg'

export const arrPrise =['от 1 260 000', 'от 1 440 000', 'от 1 701 000', 'от 1 620 000', 'от 1 625 000', 'от 900 000', 'от 1 080 000',
'от 720 000', ' от 1 920 000', ' от 1 620 000', ' от 1 890 000', ' от 1 920 000', ' от 2 010 000', ' от 1 890 000',' от 1 512 000', ' от 1 800 000', ' от 2 695 000', ' от 1 680 000', ' от 2 268 000', ' от 1 815 000', ' от 1 890 000', ' от 1 700 000', ' от 1 500 000', ' от 1 680 000', ' от 1 440 000', ' от 1 725 000', ' от 1 606 000', ' от 1 625 000', ' от 1 701 000', ' от 2 016 000', ' от 2 426 000', ' от 2 100 000', ' от 1 800 000', ' от 1 200 000', ' от 2 866 000', ' от 1 680 000', ' от 2 079 000', ' от 2 520 000', ' от 2 898 000', ' от 2 268 000', ' от 2 352 000', ' от 1 590 000', ' от 2 016 000', ' от 2 940 000', ' от 2 268 000', ' от 3 208 000', ' от 2 646 000', ' от 2 079 000', ' от 3 465 000', ' от 2 835 000',] 

export const arrPriseKa =['от 1 050 000', 'от 1 200 000', 'от 2 025 000', 'от 1 350 000', 'от 1 350 000', 'от 900 000', 'от 1 080 000','от 720 000', ' от 1 600 000', ' от 1 350 000', ' от 1 575 000', ' от 1 575 000', ' от 1 650 000', ' от 2 250 000',' от 1 800 000', ' от 1 875 000', ' от 3 200 000', ' от 2 000 000', ' от 2 700 000', ' от 1 550 000', ' от 1 575 000', ' от 1 650 000', ' от 1 250 000', ' от 1 400 000', ' от 1 050 000', ' от 2 000 000', ' от 1 900 000', ' от 1 875 000', ' от 2 025 000', ' от 2 400 000', ' от 2 875 000', ' от 2 400 000', ' от 1 800 000', ' от 1 200 000', ' от 3 400 000', ' от 1 400 000', ' от 2 475 000', ' от 3 000 000', ' от 3 450 000', ' от 3 375 000', ' от 2 875 000', ' от 1 080 000', ' от 2 10 000', ' от 4 000 000', ' от 3 250 000', ' от 3 850 000', ' от 3 500 000', ' от 2 900 000', ' от 4 125 000', ' от 3 375 000',]



export const arrDk1 = [{prise: 'от 1 260 000'}, {image: dk1_001, title: 'дом'}, {image: dk2_001, title: 'дом'}, {image: dk3_001, title: 'дом'}, {image: dk4_001, title: 'дом'}, {image: dk13_001, title: 'дом'},];
export const arrDk1s = [{image: dk5_001, title: 'дом'}, {image: dk6_001, title: 'дом'}, {image: dk7_001, title: 'дом'}, {image: dk8_001, title: 'дом'}, {image: dk13_001, title: 'дом'},];
export const arrDk1k = [{image: dk9_001, title: 'дом'}, {image: dk10_001, title: 'дом'}, {image: dk11_001, title: 'дом'}, {image: dk12_001, title: 'дом'}, {image: dk13_001, title: 'дом'},];
/////////

export const arrDk2 = [{image: dk1_002, title: 'дом'}, {image: dk2_002, title: 'дом'}, {image: dk3_002, title: 'дом'}, {image: dk4_002, title: 'дом'}, {image: dk13_002, title: 'дом'},];
export const arrDk2s = [{image: dk5_002, title: 'дом'}, {image: dk6_002, title: 'дом'}, {image: dk7_002, title: 'дом'}, {image: dk8_002, title: 'дом'}, {image: dk13_002, title: 'дом'},];
export const arrDk2k = [{image: dk9_002, title: 'дом'}, {image: dk10_002, title: 'дом'}, {image: dk11_002, title: 'дом'}, {image: dk12_002, title: 'дом'}, {image: dk13_002, title: 'дом'},];
////////////

export const arrDk3 = [{image: dk1_003, title: 'дом'}, {image: dk2_003, title: 'дом'}, {image: dk3_003, title: 'дом'}, {image: dk4_003, title: 'дом'}, {image: dk13_003, title: 'дом'},];
export const arrDk3s = [{image: dk5_003, title: 'дом'}, {image: dk6_003, title: 'дом'}, {image: dk7_003, title: 'дом'}, {image: dk8_003, title: 'дом'}, {image: dk13_003, title: 'дом'},];
export const arrDk3k = [{image: dk9_003, title: 'дом'}, {image: dk10_003, title: 'дом'}, {image: dk11_003, title: 'дом'}, {image: dk12_003, title: 'дом'}, {image: dk13_003, title: 'дом'},];
////////////

export const arrDk5 = [{image: dk1_005, title: 'дом'}, {image: dk2_005, title: 'дом'}, {image: dk3_005, title: 'дом'}, {image: dk4_005, title: 'дом'}, {image: dk13_005, title: 'дом'},];
export const arrDk5s = [{image: dk5_005, title: 'дом'}, {image: dk6_005, title: 'дом'}, {image: dk7_005, title: 'дом'}, {image: dk8_005, title: 'дом'}, {image: dk13_005, title: 'дом'},];
export const arrDk5k = [{image: dk9_005, title: 'дом'}, {image: dk10_005, title: 'дом'}, {image: dk11_005, title: 'дом'}, {image: dk12_005, title: 'дом'}, {image: dk13_005, title: 'дом'},];

///
export const arrDk6 = [{image: dk1_006, title: 'дом'}, {image: dk2_006, title: 'дом'}, {image: dk3_006, title: 'дом'}, {image: dk4_006, title: 'дом'}, {image: dk11_006, title: 'дом'},];
export const arrDk6s = [{image: dk5_006, title: 'дом'}, {image: dk6_006, title: 'дом'}, {image: dk7_006, title: 'дом'},{image: dk11_006, title: 'дом'},];
export const arrDk6k = [{image: dk8_006, title: 'дом'}, {image: dk9_006, title: 'дом'}, {image: dk10_006, title: 'дом'},{image: dk11_006, title: 'дом'},];
////////

export const arrDk8 = [{image: dk1_008, title: 'дом'}, {image: dk2_008, title: 'дом'}, {image: dk3_008, title: 'дом'}, {image: dk4_008, title: 'дом'}, {image: dk13_008, title: 'дом'},];
export const arrDk8s = [{image: dk5_008, title: 'дом'}, {image: dk6_008, title: 'дом'}, {image: dk7_008, title: 'дом'}, {image: dk8_008, title: 'дом'}, {image: dk13_008, title: 'дом'},];
export const arrDk8k = [{image: dk9_008, title: 'дом'}, {image: dk10_008, title: 'дом'}, {image: dk11_008, title: 'дом'}, {image: dk12_008, title: 'дом'}, {image: dk13_008, title: 'дом'},];
/////////

export const arrDk9 = [{image: dk1_009, title: 'дом'}, {image: dk2_009, title: 'дом'}, {image: dk3_009, title: 'дом'}, {image: dk4_009, title: 'дом'}, {image: dk13_009, title: 'дом'},];
export const arrDk9s = [{image: dk5_009, title: 'дом'}, {image: dk6_009, title: 'дом'}, {image: dk7_009, title: 'дом'}, {image: dk8_009, title: 'дом'}, {image: dk13_009, title: 'дом'},];
export const arrDk9k = [{image: dk9_009, title: 'дом'}, {image: dk10_009, title: 'дом'}, {image: dk11_009, title: 'дом'}, {image: dk12_009, title: 'дом'}, {image: dk13_009, title: 'дом'},];
////////////

export const arrDk10 = [{image: dk1_010, title: 'дом'}, {image: dk2_010, title: 'дом'}, {image: dk3_010, title: 'дом'}, {image: dk4_010, title: 'дом'}, {image: dk13_010, title: 'дом'},];
export const arrDk10s = [{image: dk5_010, title: 'дом'}, {image: dk6_010, title: 'дом'}, {image: dk7_010, title: 'дом'}, {image: dk8_010, title: 'дом'}, {image: dk13_010, title: 'дом'},];
export const arrDk10k = [{image: dk9_010, title: 'дом'}, {image: dk10_010, title: 'дом'}, {image: dk11_010, title: 'дом'}, {image: dk12_010, title: 'дом'}, {image: dk13_010, title: 'дом'},];
////////

export const arrDk11 = [{image: dk1_011, title: 'дом'}, {image: dk2_011, title: 'дом'}, {image: dk3_011, title: 'дом'}, {image: dk4_011, title: 'дом'}, {image: dk13_011, title: 'дом'},];
export const arrDk11s = [{image: dk5_011, title: 'дом'}, {image: dk6_011, title: 'дом'}, {image: dk7_011, title: 'дом'}, {image: dk8_011, title: 'дом'}, {image: dk13_011, title: 'дом'},];
export const arrDk11k = [{image: dk9_011, title: 'дом'}, {image: dk10_011, title: 'дом'}, {image: dk11_011, title: 'дом'}, {image: dk12_011, title: 'дом'}, {image: dk13_011, title: 'дом'},];
////////

export const arrDk12 = [{image: dk1_012, title: 'дом'}, {image: dk2_012, title: 'дом'}, {image: dk3_012, title: 'дом'}, {image: dk4_012, title: 'дом'}, {image: dk13_012, title: 'дом'},];
export const arrDk12s = [{image: dk5_012, title: 'дом'}, {image: dk6_012, title: 'дом'}, {image: dk7_012, title: 'дом'}, {image: dk8_012, title: 'дом'}, {image: dk13_012, title: 'дом'},];
export const arrDk12k = [{image: dk9_012, title: 'дом'}, {image: dk10_012, title: 'дом'}, {image: dk11_012, title: 'дом'}, {image: dk12_012, title: 'дом'}, {image: dk13_012, title: 'дом'},];
/////////

export const arrDk13 = [{image: dk1_013, title: 'дом'}, {image: dk2_013, title: 'дом'}, {image: dk3_013, title: 'дом'}, {image: dk4_013, title: 'дом'}, {image: dk13_013, title: 'дом'},];
export const arrDk13s = [{image: dk5_013, title: 'дом'}, {image: dk6_013, title: 'дом'}, {image: dk7_013, title: 'дом'}, {image: dk8_013, title: 'дом'}, {image: dk13_013, title: 'дом'},];
export const arrDk13k = [{image: dk9_013, title: 'дом'}, {image: dk10_013, title: 'дом'}, {image: dk11_013, title: 'дом'}, {image: dk12_013, title: 'дом'}, {image: dk13_013, title: 'дом'},];
/////////////

export const arrDk14 = [{image: dk1_014, title: 'дом'}, {image: dk2_014, title: 'дом'}, {image: dk3_014, title: 'дом'}, {image: dk4_014, title: 'дом'}, {image: dk13_014, title: 'дом'},];
export const arrDk14s = [{image: dk5_014, title: 'дом'}, {image: dk6_014, title: 'дом'}, {image: dk7_014, title: 'дом'}, {image: dk8_014, title: 'дом'}, {image: dk13_014, title: 'дом'},];
export const arrDk14k = [{image: dk9_014, title: 'дом'}, {image: dk10_014, title: 'дом'}, {image: dk11_014, title: 'дом'}, {image: dk12_014, title: 'дом'}, {image: dk13_014, title: 'дом'},];
/////////////

export const arrDk15 = [{image: dk1_015, title: 'дом'}, {image: dk2_015, title: 'дом'}, {image: dk3_015, title: 'дом'}, {image: dk4_015, title: 'дом'}, {image: dk13_015, title: 'дом'},];
export const arrDk15s = [{image: dk5_015, title: 'дом'}, {image: dk6_015, title: 'дом'}, {image: dk7_015, title: 'дом'}, {image: dk8_015, title: 'дом'}, {image: dk13_015, title: 'дом'},];
export const arrDk15k = [{image: dk9_015, title: 'дом'}, {image: dk10_015, title: 'дом'}, {image: dk11_015, title: 'дом'}, {image: dk12_015, title: 'дом'}, {image: dk13_015, title: 'дом'},];
/////////////

export const arrDk16 = [{image: dk1_016, title: 'дом'}, {image: dk2_016, title: 'дом'}, {image: dk3_016, title: 'дом'}, {image: dk4_016, title: 'дом'}, {image: dk13_016, title: 'дом'},];
export const arrDk16s = [{image: dk5_016, title: 'дом'}, {image: dk6_016, title: 'дом'}, {image: dk7_016, title: 'дом'}, {image: dk8_016, title: 'дом'}, {image: dk13_016, title: 'дом'},];
export const arrDk16k = [{image: dk9_016, title: 'дом'}, {image: dk10_016, title: 'дом'}, {image: dk11_016, title: 'дом'}, {image: dk12_016, title: 'дом'}, {image: dk13_016, title: 'дом'},];
/////////////

export const arrDk17 = [{image: dk1_017, title: 'дом'}, {image: dk2_017, title: 'дом'}, {image: dk3_017, title: 'дом'}, {image: dk4_017, title: 'дом'}, {image: dk13_017, title: 'дом'},];
export const arrDk17s = [{image: dk5_017, title: 'дом'}, {image: dk6_017, title: 'дом'}, {image: dk7_017, title: 'дом'}, {image: dk8_017, title: 'дом'}, {image: dk13_017, title: 'дом'},];
export const arrDk17k = [{image: dk9_017, title: 'дом'}, {image: dk10_017, title: 'дом'}, {image: dk11_017, title: 'дом'}, {image: dk12_017, title: 'дом'}, {image: dk13_017, title: 'дом'},];
/////////////

export const arrDk18 = [{image: dk1_018, title: 'дом'}, {image: dk2_018, title: 'дом'}, {image: dk3_018, title: 'дом'}, {image: dk4_018, title: 'дом'}, {image: dk13_018, title: 'дом'},];
export const arrDk18s = [{image: dk5_018, title: 'дом'}, {image: dk6_018, title: 'дом'}, {image: dk7_018, title: 'дом'}, {image: dk8_018, title: 'дом'}, {image: dk13_018, title: 'дом'},];
export const arrDk18k = [{image: dk9_018, title: 'дом'}, {image: dk10_018, title: 'дом'}, {image: dk11_018, title: 'дом'}, {image: dk12_018, title: 'дом'}, {image: dk13_018, title: 'дом'},];
/////////////

export const arrDk19 = [{image: dk1_019, title: 'дом'}, {image: dk2_019, title: 'дом'}, {image: dk3_019, title: 'дом'}, {image: dk4_019, title: 'дом'}, {image: dk13_019, title: 'дом'},];
export const arrDk19s = [{image: dk5_019, title: 'дом'}, {image: dk6_019, title: 'дом'}, {image: dk7_019, title: 'дом'}, {image: dk8_019, title: 'дом'}, {image: dk13_019, title: 'дом'},];
export const arrDk19k = [{image: dk9_019, title: 'дом'}, {image: dk10_019, title: 'дом'}, {image: dk11_019, title: 'дом'}, {image: dk12_019, title: 'дом'}, {image: dk13_019, title: 'дом'},];
/////////////

export const arrDk20 = [{image: dk1_020, title: 'дом'}, {image: dk2_020, title: 'дом'}, {image: dk3_020, title: 'дом'}, {image: dk4_020, title: 'дом'}, {image: dk13_020, title: 'дом'},];
export const arrDk20s = [{image: dk5_020, title: 'дом'}, {image: dk6_020, title: 'дом'}, {image: dk7_020, title: 'дом'}, {image: dk8_020, title: 'дом'}, {image: dk13_020, title: 'дом'},];
export const arrDk20k = [{image: dk9_020, title: 'дом'}, {image: dk10_020, title: 'дом'}, {image: dk11_020, title: 'дом'}, {image: dk12_020, title: 'дом'}, {image: dk13_020, title: 'дом'},];
/////////////

export const arrDk22 = [{image: dk1_022, title: 'дом'}, {image: dk2_022, title: 'дом'}, {image: dk3_022, title: 'дом'}, {image: dk4_022, title: 'дом'}, {image: dk13_022, title: 'дом'},];
export const arrDk22s = [{image: dk5_022, title: 'дом'}, {image: dk6_022, title: 'дом'}, {image: dk7_022, title: 'дом'}, {image: dk8_022, title: 'дом'}, {image: dk13_022, title: 'дом'},];
export const arrDk22k = [{image: dk9_022, title: 'дом'}, {image: dk10_022, title: 'дом'}, {image: dk11_022, title: 'дом'}, {image: dk12_022, title: 'дом'}, {image: dk13_022, title: 'дом'},];
/////////////

export const arrDk23 = [{image: dk1_023, title: 'дом'}, {image: dk2_023, title: 'дом'}, {image: dk3_023, title: 'дом'}, {image: dk4_023, title: 'дом'}, {image: dk13_023, title: 'дом'},];
export const arrDk23s = [{image: dk5_023, title: 'дом'}, {image: dk6_023, title: 'дом'}, {image: dk7_023, title: 'дом'}, {image: dk8_023, title: 'дом'}, {image: dk13_023, title: 'дом'},];
export const arrDk23k = [{image: dk9_023, title: 'дом'}, {image: dk10_023, title: 'дом'}, {image: dk11_023, title: 'дом'}, {image: dk12_023, title: 'дом'}, {image: dk13_023, title: 'дом'},];
///////////////

export const arrDk24 = [{image: dk1_024, title: 'дом'}, {image: dk2_024, title: 'дом'}, {image: dk3_024, title: 'дом'}, {image: dk4_024, title: 'дом'}, {image: dk13_024, title: 'дом'},];
export const arrDk24s = [{image: dk5_024, title: 'дом'}, {image: dk6_024, title: 'дом'}, {image: dk7_024, title: 'дом'}, {image: dk8_024, title: 'дом'}, {image: dk13_024, title: 'дом'},];
export const arrDk24k = [{image: dk9_024, title: 'дом'}, {image: dk10_024, title: 'дом'}, {image: dk11_024, title: 'дом'}, {image: dk12_024, title: 'дом'}, {image: dk13_024, title: 'дом'},];
///////////////

export const arrDk25 = [{image: dk1_025, title: 'дом'}, {image: dk2_025, title: 'дом'}, {image: dk3_025, title: 'дом'}, {image: dk4_025, title: 'дом'}, {image: dk13_025, title: 'дом'},];
export const arrDk25s = [{image: dk5_025, title: 'дом'}, {image: dk6_025, title: 'дом'}, {image: dk7_025, title: 'дом'}, {image: dk8_025, title: 'дом'}, {image: dk13_025, title: 'дом'},];
export const arrDk25k = [{image: dk9_025, title: 'дом'}, {image: dk10_025, title: 'дом'}, {image: dk11_025, title: 'дом'}, {image: dk12_025, title: 'дом'}, {image: dk13_025, title: 'дом'},];
///////////////

export const arrDk26 = [{image: dk1_026, title: 'дом'}, {image: dk2_026, title: 'дом'}, {image: dk3_026, title: 'дом'}, {image: dk4_026, title: 'дом'}, {image: dk13_026, title: 'дом'},];
export const arrDk26s = [{image: dk5_026, title: 'дом'}, {image: dk6_026, title: 'дом'}, {image: dk7_026, title: 'дом'}, {image: dk8_026, title: 'дом'}, {image: dk13_026, title: 'дом'},];
export const arrDk26k = [{image: dk9_026, title: 'дом'}, {image: dk10_026, title: 'дом'}, {image: dk11_026, title: 'дом'}, {image: dk12_026, title: 'дом'}, {image: dk13_026, title: 'дом'},];
///////////////

export const arrDk29 = [{image: dk1_029, title: 'дом'}, {image: dk2_029, title: 'дом'}, {image: dk3_029, title: 'дом'}, {image: dk4_029, title: 'дом'}, {image: dk13_029, title: 'дом'},];
export const arrDk29s = [{image: dk5_029, title: 'дом'}, {image: dk6_029, title: 'дом'}, {image: dk7_029, title: 'дом'}, {image: dk8_029, title: 'дом'}, {image: dk13_029, title: 'дом'},];
export const arrDk29k = [{image: dk9_029, title: 'дом'}, {image: dk10_029, title: 'дом'}, {image: dk11_029, title: 'дом'}, {image: dk12_029, title: 'дом'}, {image: dk13_029, title: 'дом'},];
///////////////

export const arrDk30 = [{image: dk1_030, title: 'дом'}, {image: dk2_030, title: 'дом'}, {image: dk3_030, title: 'дом'}, {image: dk4_030, title: 'дом'}, {image: dk13_030, title: 'дом'},];
export const arrDk30s = [{image: dk5_030, title: 'дом'}, {image: dk6_030, title: 'дом'}, {image: dk7_030, title: 'дом'}, {image: dk8_030, title: 'дом'}, {image: dk13_030, title: 'дом'},];
export const arrDk30k = [{image: dk9_030, title: 'дом'}, {image: dk10_030, title: 'дом'}, {image: dk11_030, title: 'дом'}, {image: dk12_030, title: 'дом'}, {image: dk13_030, title: 'дом'},];
///////////////

export const arrDk31 = [{image: dk1_031, title: 'дом'}, {image: dk2_031, title: 'дом'}, {image: dk3_031, title: 'дом'}, {image: dk4_031, title: 'дом'}, {image: dk13_031, title: 'дом'},];
export const arrDk31s = [{image: dk5_031, title: 'дом'}, {image: dk6_031, title: 'дом'}, {image: dk7_031, title: 'дом'}, {image: dk8_031, title: 'дом'}, {image: dk13_031, title: 'дом'},];
export const arrDk31k = [{image: dk9_031, title: 'дом'}, {image: dk10_031, title: 'дом'}, {image: dk11_031, title: 'дом'}, {image: dk12_031, title: 'дом'}, {image: dk13_031, title: 'дом'},];
///////////////

export const arrDk35 = [{image: dk1_035, title: 'дом'}, {image: dk2_035, title: 'дом'}, {image: dk3_035, title: 'дом'}, {image: dk4_035, title: 'дом'}, {image: dk13_035, title: 'дом'},];
export const arrDk35s = [{image: dk5_035, title: 'дом'}, {image: dk6_035, title: 'дом'}, {image: dk7_035, title: 'дом'}, {image: dk8_035, title: 'дом'}, {image: dk13_035, title: 'дом'},];
export const arrDk35k = [{image: dk9_035, title: 'дом'}, {image: dk10_035, title: 'дом'}, {image: dk11_035, title: 'дом'}, {image: dk12_035, title: 'дом'}, {image: dk13_035, title: 'дом'},];
///////////////

export const arrDk36 = [{image: dk1_036, title: 'дом'}, {image: dk2_036, title: 'дом'}, {image: dk3_036, title: 'дом'}, {image: dk4_036, title: 'дом'}, {image: dk13_036, title: 'дом'},];
export const arrDk36s = [{image: dk5_036, title: 'дом'}, {image: dk6_036, title: 'дом'}, {image: dk7_036, title: 'дом'}, {image: dk8_036, title: 'дом'}, {image: dk13_036, title: 'дом'},];
export const arrDk36k = [{image: dk9_036, title: 'дом'}, {image: dk10_036, title: 'дом'}, {image: dk11_036, title: 'дом'}, {image: dk12_036, title: 'дом'}, {image: dk13_036, title: 'дом'},];
///////////////

export const arrDk38 = [{image: dk1_038, title: 'дом'}, {image: dk2_038, title: 'дом'}, {image: dk3_038, title: 'дом'}, {image: dk4_038, title: 'дом'}, {image: dk13_038, title: 'дом'},];
export const arrDk38s = [{image: dk5_038, title: 'дом'}, {image: dk6_038, title: 'дом'}, {image: dk7_038, title: 'дом'}, {image: dk8_038, title: 'дом'}, {image: dk13_038, title: 'дом'},];
export const arrDk38k = [{image: dk9_038, title: 'дом'}, {image: dk10_038, title: 'дом'}, {image: dk11_038, title: 'дом'}, {image: dk12_038, title: 'дом'}, {image: dk13_038, title: 'дом'},];
///////////////

export const arrDk39 = [{image: dk1_039, title: 'дом'}, {image: dk2_039, title: 'дом'}, {image: dk3_039, title: 'дом'}, {image: dk4_039, title: 'дом'}, {image: dk13_039, title: 'дом'},];
export const arrDk39s = [{image: dk5_039, title: 'дом'}, {image: dk6_039, title: 'дом'}, {image: dk7_039, title: 'дом'}, {image: dk8_039, title: 'дом'}, {image: dk13_039, title: 'дом'},];
export const arrDk39k = [{image: dk9_039, title: 'дом'}, {image: dk10_039, title: 'дом'}, {image: dk11_039, title: 'дом'}, {image: dk12_039, title: 'дом'}, {image: dk13_039, title: 'дом'},];
///////////////

export const arrDk40 = [{image: dk1_040, title: 'дом'}, {image: dk2_040, title: 'дом'}, {image: dk3_040, title: 'дом'}, {image: dk4_040, title: 'дом'}, {image: dk13_040, title: 'дом'},];
export const arrDk40s = [{image: dk5_040, title: 'дом'}, {image: dk6_040, title: 'дом'}, {image: dk7_040, title: 'дом'}, {image: dk8_040, title: 'дом'}, {image: dk13_040, title: 'дом'},];
export const arrDk40k = [{image: dk9_040, title: 'дом'}, {image: dk10_040, title: 'дом'}, {image: dk11_040, title: 'дом'}, {image: dk12_040, title: 'дом'}, {image: dk13_040, title: 'дом'},];
///////////////

export const arrDk41 = [{image: dk1_041, title: 'дом'}, {image: dk2_041, title: 'дом'}, {image: dk3_041, title: 'дом'}, {image: dk4_041, title: 'дом'}, {image: dk13_041, title: 'дом'},];
export const arrDk41s = [{image: dk5_041, title: 'дом'}, {image: dk6_041, title: 'дом'}, {image: dk7_041, title: 'дом'}, {image: dk8_041, title: 'дом'}, {image: dk13_041, title: 'дом'},];
export const arrDk41k = [{image: dk9_041, title: 'дом'}, {image: dk10_041, title: 'дом'}, {image: dk11_041, title: 'дом'}, {image: dk12_041, title: 'дом'}, {image: dk13_041, title: 'дом'},];
///////////////

export const arrDk42 = [{image: dk1_042, title: 'дом'}, {image: dk2_042, title: 'дом'}, {image: dk3_042, title: 'дом'}, {image: dk4_042, title: 'дом'}, {image: dk13_042, title: 'дом'},];
export const arrDk42s = [{image: dk5_042, title: 'дом'}, {image: dk6_042, title: 'дом'}, {image: dk7_042, title: 'дом'}, {image: dk8_042, title: 'дом'}, {image: dk13_042, title: 'дом'},];
export const arrDk42k = [{image: dk9_042, title: 'дом'}, {image: dk10_042, title: 'дом'}, {image: dk11_042, title: 'дом'}, {image: dk12_042, title: 'дом'}, {image: dk13_042, title: 'дом'},];
///////////////

export const arrDk44 = [{image: dk1_044, title: 'дом'}, {image: dk2_044, title: 'дом'}, {image: dk3_044, title: 'дом'}, {image: dk4_044, title: 'дом'}, {image: dk13_044, title: 'дом'},];
export const arrDk44s = [{image: dk5_044, title: 'дом'}, {image: dk6_044, title: 'дом'}, {image: dk7_044, title: 'дом'}, {image: dk8_044, title: 'дом'}, {image: dk13_044, title: 'дом'},];
export const arrDk44k = [{image: dk9_044, title: 'дом'}, {image: dk10_044, title: 'дом'}, {image: dk11_044, title: 'дом'}, {image: dk12_044, title: 'дом'}, {image: dk13_044, title: 'дом'},];
///////////////

export const arrDk45 = [{image: dk1_045, title: 'дом'}, {image: dk2_045, title: 'дом'}, {image: dk3_045, title: 'дом'}, {image: dk4_045, title: 'дом'}, {image: dk13_045, title: 'дом'},];
export const arrDk45s = [{image: dk5_045, title: 'дом'}, {image: dk6_045, title: 'дом'}, {image: dk7_045, title: 'дом'}, {image: dk8_045, title: 'дом'}, {image: dk13_045, title: 'дом'},];
export const arrDk45k = [{image: dk9_045, title: 'дом'}, {image: dk10_045, title: 'дом'}, {image: dk11_045, title: 'дом'}, {image: dk12_045, title: 'дом'}, {image: dk13_045, title: 'дом'},];
///////////////

export const arrDk46 = [{image: dk1_046, title: 'дом'}, {image: dk2_046, title: 'дом'}, {image: dk3_046, title: 'дом'}, {image: dk4_046, title: 'дом'}, {image: dk13_046, title: 'дом'},];
export const arrDk46s = [{image: dk5_046, title: 'дом'}, {image: dk6_046, title: 'дом'}, {image: dk7_046, title: 'дом'}, {image: dk8_046, title: 'дом'}, {image: dk13_046, title: 'дом'},];
export const arrDk46k = [{image: dk9_046, title: 'дом'}, {image: dk10_046, title: 'дом'}, {image: dk11_046, title: 'дом'}, {image: dk12_046, title: 'дом'}, {image: dk13_046, title: 'дом'},];
///////////////

export const arrDk47 = [{image: dk1_047, title: 'дом'}, {image: dk2_047, title: 'дом'}, {image: dk3_047, title: 'дом'}, {image: dk4_047, title: 'дом'}, {image: dk13_047, title: 'дом'},];
export const arrDk47s = [{image: dk5_047, title: 'дом'}, {image: dk6_047, title: 'дом'}, {image: dk7_047, title: 'дом'}, {image: dk8_047, title: 'дом'}, {image: dk13_047, title: 'дом'},];
export const arrDk47k = [{image: dk9_047, title: 'дом'}, {image: dk10_047, title: 'дом'}, {image: dk11_047, title: 'дом'}, {image: dk12_047, title: 'дом'}, {image: dk13_047, title: 'дом'},];
///////////////

export const arrDk49 = [{image: dk1_049, title: 'дом'}, {image: dk2_049, title: 'дом'}, {image: dk3_049, title: 'дом'}, {image: dk4_049, title: 'дом'}, {image: dk13_049, title: 'дом'},];
export const arrDk49s = [{image: dk5_049, title: 'дом'}, {image: dk6_049, title: 'дом'}, {image: dk7_049, title: 'дом'}, {image: dk8_049, title: 'дом'}, {image: dk13_049, title: 'дом'},];
export const arrDk49k = [{image: dk9_049, title: 'дом'}, {image: dk10_049, title: 'дом'}, {image: dk11_049, title: 'дом'}, {image: dk12_049, title: 'дом'}, {image: dk13_049, title: 'дом'},];
///////////////

export const arrDk50 = [{image: dk1_050, title: 'дом'}, {image: dk2_050, title: 'дом'}, {image: dk3_050, title: 'дом'}, {image: dk4_050, title: 'дом'}, {image: dk13_050, title: 'дом'},];
export const arrDk50s = [{image: dk5_050, title: 'дом'}, {image: dk6_050, title: 'дом'}, {image: dk7_050, title: 'дом'}, {image: dk8_050, title: 'дом'}, {image: dk13_050, title: 'дом'},];
export const arrDk50k = [{image: dk9_050, title: 'дом'}, {image: dk10_050, title: 'дом'}, {image: dk11_050, title: 'дом'}, {image: dk12_050, title: 'дом'}, {image: dk13_050, title: 'дом'},];
///////////////

export const arrDk302 = [{image: dk1_302, title: 'дом'}, {image: dk2_302, title: 'дом'}, {image: dk3_302, title: 'дом'}, {image: dk4_302, title: 'дом'}, {image: dk13_302, title: 'дом'}, {image: dk14_302, title: 'дом'},];
export const arrDk302s = [{image: dk5_302, title: 'дом'}, {image: dk6_302, title: 'дом'}, {image: dk7_302, title: 'дом'}, {image: dk8_302, title: 'дом'}, {image: dk13_302, title: 'дом'}, {image: dk14_302, title: 'дом'},];
export const arrDk302k = [{image: dk9_302, title: 'дом'}, {image: dk10_302, title: 'дом'}, {image: dk11_302, title: 'дом'}, {image: dk12_302, title: 'дом'}, {image: dk13_302, title: 'дом'}, {image: dk14_302, title: 'дом'},];
///////////////

export const arrDk303 = [{image: dk1_303, title: 'дом'}, {image: dk2_303, title: 'дом'}, {image: dk3_303, title: 'дом'}, {image: dk4_303, title: 'дом'}, {image: dk13_303, title: 'дом'}, {image: dk14_303, title: 'дом'},];
export const arrDk303s = [{image: dk5_303, title: 'дом'}, {image: dk6_303, title: 'дом'}, {image: dk7_303, title: 'дом'}, {image: dk8_303, title: 'дом'}, {image: dk13_303, title: 'дом'}, {image: dk14_303, title: 'дом'},];
export const arrDk303k = [{image: dk9_303, title: 'дом'}, {image: dk10_303, title: 'дом'}, {image: dk11_303, title: 'дом'}, {image: dk12_303, title: 'дом'}, {image: dk13_303, title: 'дом'}, {image: dk14_303, title: 'дом'},];
///////////////

export const arrDk304 = [{image: dk1_304, title: 'дом'}, {image: dk2_304, title: 'дом'}, {image: dk3_304, title: 'дом'}, {image: dk4_304, title: 'дом'}, {image: dk13_304, title: 'дом'}, {image: dk14_304, title: 'дом'},];
export const arrDk304s = [{image: dk5_304, title: 'дом'}, {image: dk6_304, title: 'дом'}, {image: dk7_304, title: 'дом'}, {image: dk8_304, title: 'дом'}, {image: dk13_304, title: 'дом'}, {image: dk14_304, title: 'дом'},];
export const arrDk304k = [{image: dk9_304, title: 'дом'}, {image: dk10_304, title: 'дом'}, {image: dk11_304, title: 'дом'}, {image: dk12_304, title: 'дом'}, {image: dk13_304, title: 'дом'}, {image: dk14_304, title: 'дом'},];
///////////////

export const arrDk305 = [{image: dk1_305, title: 'дом'}, {image: dk2_305, title: 'дом'}, {image: dk3_305, title: 'дом'}, {image: dk4_305, title: 'дом'}, {image: dk13_305, title: 'дом'}, {image: dk14_305, title: 'дом'},];
export const arrDk305s = [{image: dk5_305, title: 'дом'}, {image: dk6_305, title: 'дом'}, {image: dk7_305, title: 'дом'}, {image: dk8_305, title: 'дом'}, {image: dk13_305, title: 'дом'}, {image: dk14_305, title: 'дом'},];
export const arrDk305k = [{image: dk9_305, title: 'дом'}, {image: dk10_305, title: 'дом'}, {image: dk11_305, title: 'дом'}, {image: dk12_305, title: 'дом'}, {image: dk13_305, title: 'дом'}, {image: dk14_305, title: 'дом'},];
///////////////

export const arrDk307 = [{image: dk1_307, title: 'дом'}, {image: dk2_307, title: 'дом'}, {image: dk3_307, title: 'дом'}, {image: dk4_307, title: 'дом'}, {image: dk13_307, title: 'дом'}, {image: dk14_307, title: 'дом'},];
export const arrDk307s = [{image: dk5_307, title: 'дом'}, {image: dk6_307, title: 'дом'}, {image: dk7_307, title: 'дом'}, {image: dk8_307, title: 'дом'}, {image: dk13_307, title: 'дом'}, {image: dk14_307, title: 'дом'},];
export const arrDk307k = [{image: dk9_307, title: 'дом'}, {image: dk10_307, title: 'дом'}, {image: dk11_307, title: 'дом'}, {image: dk12_307, title: 'дом'}, {image: dk13_307, title: 'дом'}, {image: dk14_307, title: 'дом'},];
///////////////

export const arrDk310 = [{image: dk1_310, title: 'дом'}, {image: dk2_310, title: 'дом'}, {image: dk3_310, title: 'дом'}, {image: dk4_310, title: 'дом'}, {image: dk13_310, title: 'дом'}, {image: dk14_310, title: 'дом'},];
export const arrDk310s = [{image: dk5_310, title: 'дом'}, {image: dk6_310, title: 'дом'}, {image: dk7_310, title: 'дом'}, {image: dk8_310, title: 'дом'}, {image: dk13_310, title: 'дом'}, {image: dk14_310, title: 'дом'},];
export const arrDk310k = [{image: dk9_310, title: 'дом'}, {image: dk10_310, title: 'дом'}, {image: dk11_310, title: 'дом'}, {image: dk12_310, title: 'дом'}, {image: dk13_310, title: 'дом'}, {image: dk14_310, title: 'дом'},];
///////////////

export const arrDk316 = [{image: dk1_316, title: 'дом'}, {image: dk2_316, title: 'дом'}, {image: dk3_316, title: 'дом'}, {image: dk4_316, title: 'дом'}, {image: dk13_316, title: 'дом'}, {image: dk14_316, title: 'дом'},];
export const arrDk316s = [{image: dk5_316, title: 'дом'}, {image: dk6_316, title: 'дом'}, {image: dk7_316, title: 'дом'}, {image: dk8_316, title: 'дом'}, {image: dk13_316, title: 'дом'}, {image: dk14_316, title: 'дом'},];
export const arrDk316k = [{image: dk9_316, title: 'дом'}, {image: dk10_316, title: 'дом'}, {image: dk11_316, title: 'дом'}, {image: dk12_316, title: 'дом'}, {image: dk13_316, title: 'дом'}, {image: dk14_316, title: 'дом'},];
///////////////

export const arrDk319 = [{image: dk1_319, title: 'дом'}, {image: dk2_319, title: 'дом'}, {image: dk3_319, title: 'дом'}, {image: dk4_319, title: 'дом'}, {image: dk13_319, title: 'дом'}, {image: dk14_319, title: 'дом'},];
export const arrDk319s = [{image: dk5_319, title: 'дом'}, {image: dk6_319, title: 'дом'}, {image: dk7_319, title: 'дом'}, {image: dk8_319, title: 'дом'}, {image: dk13_319, title: 'дом'}, {image: dk14_319, title: 'дом'},];
export const arrDk319k = [{image: dk9_319, title: 'дом'}, {image: dk10_319, title: 'дом'}, {image: dk11_319, title: 'дом'}, {image: dk12_319, title: 'дом'}, {image: dk13_319, title: 'дом'}, {image: dk14_319, title: 'дом'},];
///////////////

export const arrDk320 = [{image: dk1_320, title: 'дом'}, {image: dk2_320, title: 'дом'}, {image: dk3_320, title: 'дом'}, {image: dk4_320, title: 'дом'}, {image: dk13_320, title: 'дом'}, {image: dk14_320, title: 'дом'},];
export const arrDk320s = [{image: dk5_320, title: 'дом'}, {image: dk6_320, title: 'дом'}, {image: dk7_320, title: 'дом'}, {image: dk8_320, title: 'дом'}, {image: dk13_320, title: 'дом'}, {image: dk14_320, title: 'дом'},];
export const arrDk320k = [{image: dk9_320, title: 'дом'}, {image: dk10_320, title: 'дом'}, {image: dk11_320, title: 'дом'}, {image: dk12_320, title: 'дом'}, {image: dk13_320, title: 'дом'}, {image: dk14_320, title: 'дом'},];
///////////////

export const arrDk602 = [{image: dk1_602, title: 'дом'}, {image: dk2_602, title: 'дом'}, {image: dk3_602, title: 'дом'}, {image: dk4_602, title: 'дом'}, {image: dk13_602, title: 'дом'}, {image: dk14_602, title: 'дом'},];
export const arrDk602s = [{image: dk5_602, title: 'дом'}, {image: dk6_602, title: 'дом'}, {image: dk7_602, title: 'дом'}, {image: dk8_602, title: 'дом'}, {image: dk13_602, title: 'дом'}, {image: dk14_602, title: 'дом'},];
export const arrDk602k = [{image: dk9_602, title: 'дом'}, {image: dk10_602, title: 'дом'}, {image: dk11_602, title: 'дом'}, {image: dk12_602, title: 'дом'}, {image: dk13_602, title: 'дом'}, {image: dk14_602, title: 'дом'},];
///////////////

export const arrDk606 = [{image: dk1_606, title: 'дом'}, {image: dk2_606, title: 'дом'}, {image: dk3_606, title: 'дом'}, {image: dk4_606, title: 'дом'}, {image: dk13_606, title: 'дом'}, {image: dk14_606, title: 'дом'},];
export const arrDk606s = [{image: dk5_606, title: 'дом'}, {image: dk6_606, title: 'дом'}, {image: dk7_606, title: 'дом'}, {image: dk8_606, title: 'дом'}, {image: dk13_606, title: 'дом'}, {image: dk14_606, title: 'дом'},];
export const arrDk606k = [{image: dk9_606, title: 'дом'}, {image: dk10_606, title: 'дом'}, {image: dk11_606, title: 'дом'}, {image: dk12_606, title: 'дом'}, {image: dk13_606, title: 'дом'}, {image: dk14_606, title: 'дом'},];