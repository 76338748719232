import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './DomaKarkasnye.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'

import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/House/dk-001/img_10.jpg'
import img_2 from '../../image/House/dk-002/img_10.jpg'
import img_3 from '../../image/House/dk-003/img_10.jpg'
import img_5 from '../../image/House/dk-005/img_10.jpg'
import img_6 from '../../image/House/dk-006/img_8.jpg'
import img_8 from '../../image/House/dk-008/img_9.jpg'
import img_9 from '../../image/House/dk-009/img_10.jpg'
import img_10 from '../../image/House/dk-010/img_10.jpg'
import img_11 from '../../image/House/dk-011/img_10.jpg'
import img_12 from '../../image/House/dk-012/img_10.jpg'
import img_13 from '../../image/House/dk-013/img_10.jpg'
import img_14 from '../../image/House/dk-014/img_9.jpg'
import img_15 from '../../image/House/dk-015/img_9.jpg'
import img_16 from '../../image/House/dk-016/img_9.jpg'
import img_17 from '../../image/House/dk-017/img_9.jpg'
import img_18 from '../../image/House/dk-018/img_9.jpg'
import img_19 from '../../image/House/dk-019/img_9.jpg'
import img_20 from '../../image/House/dk-020/img_9.jpg'
import img_22 from '../../image/House/dk-022/img_9.jpg'
import img_23 from '../../image/House/dk-023/img_9.jpg'
import img_24 from '../../image/House/dk-024/img_9.jpg'
import img_25 from '../../image/House/dk-025/img_9.jpg'
import img_26 from '../../image/House/dk-026/img_9.jpg'
import img_29 from '../../image/House/dk-029/img_9.jpg'
import img_30 from '../../image/House/dk-030/img_9.jpg'
import img_31 from '../../image/House/dk-031/img_9.jpg'
import img_35 from '../../image/House/dk-035/img_9.jpg'
import img_36 from '../../image/House/dk-036/img_9.jpg'
import img_38 from '../../image/House/dk-038/img_9.jpg'
import img_39 from '../../image/House/dk-039/img_9.jpg'
import img_40 from '../../image/House/dk-040/img_9.jpg'
import img_41 from '../../image/House/dk-041/img_9.jpg'
import img_42 from '../../image/House/dk-042/img_9.jpg'
import img_44 from '../../image/House/dk-044/img_9.jpg'
import img_45 from '../../image/House/dk-045/img_9.jpg'
import img_46 from '../../image/House/dk-046/img_9.jpg'
import img_47 from '../../image/House/dk-047/img_9.jpg'
import img_49 from '../../image/House/dk-049/img_9.jpg'
import img_50 from '../../image/House/dk-050/img_9.jpg'
import img_302 from '../../image/House/dk-302/img_9.jpg'
import img_303 from '../../image/House/dk-303/img_9.jpg'
import img_304 from '../../image/House/dk-304/img_9.jpg'
import img_305 from '../../image/House/dk-305/img_9.jpg'
import img_307 from '../../image/House/dk-307/img_9.jpg'
import img_310 from '../../image/House/dk-310/img_9.jpg'
import img_316 from '../../image/House/dk-316/img_9.jpg'
import img_319 from '../../image/House/dk-319/img_9.jpg'
import img_320 from '../../image/House/dk-320/img_9.jpg'
import img_602 from '../../image/House/dk-602/img_9.jpg'
import img_606 from '../../image/House/dk-606/img_9.jpg'

import { arrDk1k } from '../../utils/dk'
import { arrDk2k } from '../../utils/dk'
import { arrDk3k } from '../../utils/dk'
import { arrDk5k } from '../../utils/dk'
import { arrDk6k } from '../../utils/dk'
import { arrDk8k } from '../../utils/dk'
import { arrDk9k } from '../../utils/dk'
import { arrDk10k } from '../../utils/dk'
import { arrDk11k } from '../../utils/dk'
import { arrDk12k } from '../../utils/dk'
import { arrDk13k } from '../../utils/dk'
import { arrDk14k } from '../../utils/dk'
import { arrDk15k } from '../../utils/dk'
import { arrDk16k } from '../../utils/dk'
import { arrDk17k } from '../../utils/dk'
import { arrDk18k } from '../../utils/dk'
import { arrDk19k } from '../../utils/dk'
import { arrDk20k } from '../../utils/dk'
import { arrDk22k } from '../../utils/dk'
import { arrDk23k } from '../../utils/dk'
import { arrDk24k } from '../../utils/dk'
import { arrDk25k } from '../../utils/dk'
import { arrDk26k } from '../../utils/dk'
import { arrDk29k } from '../../utils/dk'
import { arrDk30k } from '../../utils/dk'
import { arrDk31k } from '../../utils/dk'
import { arrDk35k } from '../../utils/dk'
import { arrDk36k } from '../../utils/dk'
import { arrDk38k } from '../../utils/dk'
import { arrDk39k } from '../../utils/dk'
import { arrDk40k } from '../../utils/dk'
import { arrDk41k } from '../../utils/dk'
import { arrDk42k } from '../../utils/dk'
import { arrDk44k } from '../../utils/dk'
import { arrDk45k } from '../../utils/dk'
import { arrDk46k } from '../../utils/dk'
import { arrDk47k } from '../../utils/dk'
import { arrDk49k } from '../../utils/dk'
import { arrDk50k } from '../../utils/dk'
import { arrDk302k } from '../../utils/dk'
import { arrDk303k } from '../../utils/dk'
import { arrDk304k } from '../../utils/dk'
import { arrDk305k } from '../../utils/dk'
import { arrDk307k } from '../../utils/dk'
import { arrDk310k } from '../../utils/dk'
import { arrDk316k } from '../../utils/dk'
import { arrDk319k } from '../../utils/dk'
import { arrDk320k } from '../../utils/dk'
import { arrDk602k } from '../../utils/dk'
import { arrDk606k } from '../../utils/dk'
import { arrPriseKa } from '../../utils/dk'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const DomaKarkasnye = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const house = 'Каркасный дом';

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Каркасные дома'}
                    />
            <div className={styles.box}>
                    <h2 className={styles.title}>Готовые проекты<span> каркасных домов</span></h2>
                    <ul className={styles.list}>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-001</h3>
                        <p className={styles.cell_text}>{house} 7х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[0]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk1k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-002</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[1]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk2k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-003</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[2]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk3k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_5} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-005</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[3]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk5k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_6} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-006</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[4]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk6k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_8} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-008</h3>
                        <p className={styles.cell_text}>{house} 6х5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[5]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk8k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_9} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-009</h3>
                        <p className={styles.cell_text}>{house} 6х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[6]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk9k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_10} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-010</h3>
                        <p className={styles.cell_text}>{house} 6х4 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[7]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk10k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_11} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-011</h3>
                        <p className={styles.cell_text}>{house} 8х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[8]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk11k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_12} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-012</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[9]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk12k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_13} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-013</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[10]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk13k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_14} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-014</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[11]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk14k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_15} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-015</h3>
                        <p className={styles.cell_text}>{house} 9.5х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[12]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk15k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_16} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-016</h3>
                        <p className={styles.cell_text}>{house} 10х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[13]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk16k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_17} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-017</h3>
                        <p className={styles.cell_text}>{house} 7.2х10 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[14]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk17k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_18} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-018</h3>
                        <p className={styles.cell_text}>{house} 9.5х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[15]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk18k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_19} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-019</h3>
                        <p className={styles.cell_text}>{house} 13.5х9.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[16]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk19k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_20} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-020</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[17]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk20k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_22} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-022</h3>
                        <p className={styles.cell_text}>{house} 12х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[18]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk22k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_23} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-023</h3>
                        <p className={styles.cell_text}>{house} 9.5х6.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[19]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk23k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_24} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-024</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[20]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk24k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_25} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-025</h3>
                        <p className={styles.cell_text}>{house} 10.5х7.7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[21]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk25k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_26} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-026</h3>
                        <p className={styles.cell_text}>{house} 5х10 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[22]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk26k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_29} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-029</h3>
                        <p className={styles.cell_text}>{house} 8х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[23]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk29k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_30} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-030</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[24]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk30k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_31} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-031</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[25]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk31k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_35} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-035</h3>
                        <p className={styles.cell_text}>{house} 9х8.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[26]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk35k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_36} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-036</h3>
                        <p className={styles.cell_text}>{house} 8х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[27]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk36k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_38} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-038</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[28]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk38k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_39} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-039</h3>
                        <p className={styles.cell_text}>{house} 12х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[29]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk39k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_40} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-040</h3>
                        <p className={styles.cell_text}>{house} 11х10.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[30]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk40k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_41} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-041</h3>
                        <p className={styles.cell_text}>{house} 12х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[31]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk41k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_42} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-042</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[32]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk42k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_44} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-044</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[33]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk44k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_45} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-045</h3>
                        <p className={styles.cell_text}>{house} 13х10.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[34]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk45k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_46} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-046</h3>
                        <p className={styles.cell_text}>{house} 8х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[35]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk46k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_47} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-047</h3>
                        <p className={styles.cell_text}>{house} 9х11 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[36]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk47k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_49} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-049</h3>
                        <p className={styles.cell_text}>{house} 12х11 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[37]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk49k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_50} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-050</h3>
                        <p className={styles.cell_text}>{house} 12х11.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[38]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk50k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_302} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-302</h3>
                        <p className={styles.cell_text}>{house} 9х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[39]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk302k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_303} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-303</h3>
                        <p className={styles.cell_text}>{house} 10х7 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[40]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk303k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_304} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-304</h3>
                        <p className={styles.cell_text}>{house} 4х6 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[41]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk304k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_305} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-305</h3>
                        <p className={styles.cell_text}>{house} 6х8 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[42]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk305k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_307} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-307</h3>
                        <p className={styles.cell_text}>{house} 10х8.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[43]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk307k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_310} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-310</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[44]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk310k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_316} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-316</h3>
                        <p className={styles.cell_text}>{house} 12х10 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[45]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk316k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_319} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-319</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[46]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk319k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_320} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-320</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[47]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk320k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_602} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-602</h3>
                        <p className={styles.cell_text}>{house} 12х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[48]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk602k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_606} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-606</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPriseKa[49]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk606k)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                </div>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default DomaKarkasnye
