import React from 'react'
import { motion } from "framer-motion"
import styles from './TeamMembers.module.scss'
import img_1 from '../../image/TeamMembers/img_1.png'
import img_2 from '../../image/TeamMembers/img_2.jpg'
import img_3 from '../../image/TeamMembers/img_3.jpg'
import img_4 from '../../image/TeamMembers/img_3.png'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const TeamMembers = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <motion.h2 className={styles.subtitle} variants={titleAnimation}>Наша <span>команда</span></motion.h2>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_1} alt='дизайн'/>
                        <p className={styles.item_title}>Ильнар Яппаров</p>
                        <p className={styles.item_text}>Руководитель компании</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_2} alt='дизайн'/>
                        <p className={styles.item_title}>Анна Яппарова</p>
                        <p className={styles.item_text}>Архитектор</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_4} alt='дизайн'/>
                        <p className={styles.item_title}>Евгений Дранко</p>
                        <p className={styles.item_text}>Главный инженер</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_3} alt='дизайн'/>
                        <p className={styles.item_title}>Ильнур Шафиков</p>
                        <p className={styles.item_text}>Главный технолог</p>
                    </motion.li>
                </ul>
            </div>
        </motion.section>
    )
}

export default TeamMembers