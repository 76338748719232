import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './ListWorks.module.scss'
import img_1 from '../../image/ListWorks/img_1.webp'
import img_2 from '../../image/ListWorks/img_2.webp'
import img_3 from '../../image/ListWorks/img_3.webp'
import img_4 from '../../image/ListWorks/img_4.webp'
import img_5 from '../../image/ListWorks/img_5.webp'
import img_6 from '../../image/ListWorks/img_6.webp'
import img_7 from '../../image/ListWorks/img_7.webp'
import img_8 from '../../image/ListWorks/img_8.webp'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const ListWorks = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
        className={styles.main}
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}> 
                    <motion.h2 className={styles.subtitle} variants={titleAnimation}>Наш каталог <span>готовых проектов домов&#160;и&#160;бань</span></motion.h2>
                    <ul className={styles.list}>
                    <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_1} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Каркасные дома</p>
                                </div>
                            </div>
                            <Link className={styles.link} to="/doma-karkasnye">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_2} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Дома из бруса</p>
                                </div>
                            </div>
                            <Link className={styles.link} to="/doma-brus">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_3} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Дома из сип панелей</p>
                                </div>
                            </div>    
                            <Link className={styles.link} to="/doma-sip-paneli">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_8} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >A-frame & Barn House</p>
                                </div>
                            </div>    
                            <Link className={styles.link} to="/doma-frejm">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_4} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Кирпичные дома</p>
                                </div>
                            </div>    
                            <Link className={styles.link} to="/doma-kirpichnye">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_5} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Бани из бруса</p>
                                </div>
                            </div>
                            <Link className={styles.link} to="/bani-brus">Смотреть каталог</Link>    
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_6} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Каркасные бани</p>
                                </div>
                            </div>    
                            <Link className={styles.link} to="/karkasnye-bani">Смотреть каталог</Link>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_7} alt='дизайн'/>
                                <div className={styles.box_text}>
                                    <p className={styles.text} >Мобильные бани</p>
                                </div>
                            </div>    
                            <Link className={styles.link} to="/mobilnye-bani">Смотреть каталог</Link>
                        </motion.li>
                    </ul>
            </div>
        </motion.section>
    )
}

export default ListWorks