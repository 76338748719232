import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './DomaFrejm.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/DomaFrejm/af-001/img_1.jpg'
import img_2 from '../../image/DomaFrejm/af-006/img_1.jpg'
import img_3 from '../../image/DomaFrejm/af-007/img_1.jpg'
import img_4 from '../../image/DomaFrejm/af-008/img_1.jpg'
import img_5 from '../../image/DomaFrejm/af-009/img_1.jpg'
import img_6 from '../../image/DomaFrejm/dbx-002/img_1.jpg'

///// массивы
import { arrAf1 } from '../../utils/af'
import { arrAf2 } from '../../utils/af'
import { arrAf3 } from '../../utils/af'
import { arrAf4 } from '../../utils/af'
import { arrAf5 } from '../../utils/af'
import { arrAf6 } from '../../utils/af'


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const DomaFrejm = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const house = 'Каркасный дом';

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'A-frame'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.title}>Готовые проекты домов<span> A&#8205;-&#8205;frame&#160;&&#160;Barn&#160;House</span></h2>
                    <ul className={styles.list}>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект АФ-001</h3>
                        <p className={styles.cell_text}>{house} 6х7.6 м.</p>
                        <p className={styles.cell_number}>от 1 800 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf1)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект АФ-006</h3>
                        <p className={styles.cell_text}>{house} 5х7.5 м.</p>
                        <p className={styles.cell_number}>от 1 125 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf2)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект АФ-007</h3>
                        <p className={styles.cell_text}>{house} 7х10 м.</p>
                        <p className={styles.cell_number}>от 1 974 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf3)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_4} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект АФ-008</h3>
                        <p className={styles.cell_text}>{house} 8х8 м.</p>
                        <p className={styles.cell_number}>от 2 352 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf4)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_5} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект АФ-009</h3>
                        <p className={styles.cell_text}>{house} 6х6 м.</p>
                        <p className={styles.cell_number}>от 1 785 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf5)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_6} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДБХ-002</h3>
                        <p className={styles.cell_text}>{house} 8,2х13,5 м.</p>
                        <p className={styles.cell_number}>от 1 985 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrAf6)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                </div>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default DomaFrejm