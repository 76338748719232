import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/InitialPage/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='люди' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title} variants={titleAnimation}>Лучшие решения, экологичные материалы, непревзойденное качество — <span>все&#160;это&#160;о&#160;Я‍-‍STROY</span></h2>
                        <p className={styles.text}>&#8195;Мы знаем, как сложно сегодня построить маленькую дачу, баню или большой коттедж для всей семьи – найти грамотных и надежных исполнителей, выбрать оптимальный проект, проконтролировать работы. Наши сотрудники возьмут все хлопоты на себя. Вам останется лишь следить за тем, как ваша мечта воплощается в реальность. Удобный формат сотрудничества, проекты на любой вкус и кошелек, точное соблюдение сроков и технологии строительства – все это вы получаете, обращаясь в Я‍-‍STROY.</p>
                        <p className={styles.text}>Мы занимается реализацией проекта на всех стадиях, от фундамента до чистовой отделки, с монтажом инженерных коммуникаций и осуществляем гарантийную поддержку после окончания строительства.</p>
                        <p className={styles.text}>Дом или баня, построенные специалистами Я‍-‍STROY, будет сохраняться в идеальном состоянии на протяжении долгих лет, не нуждаясь в частом ремонте.</p>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    )
}

export default AboutUs