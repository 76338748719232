import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './DomaKirpichnye.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/DomaKirpichnye/kd-001/img_1.jpg'
import img_2 from '../../image/DomaKirpichnye/kd-002/img_1.jpg'
import img_3 from '../../image/DomaKirpichnye/kd-003/img_1.jpg'
import img_4 from '../../image/DomaKirpichnye/kd-004/img_1.jpg'

import { arrKd1 } from '../../utils/kd'
import { arrKd2 } from '../../utils/kd'
import { arrKd3 } from '../../utils/kd'
import { arrKd4 } from '../../utils/kd'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const DomaKirpichnye = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const house = 'Кирпичный дом';

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Кирпичные дома'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.title}>Готовые проекты<span> кирпичных домов</span></h2>
                </div>
                <ul className={styles.list}>
                <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект КД-001</h3>
                        <p className={styles.cell_text}>{house} 8.4х13 м.</p>
                        <p className={styles.cell_number}>от 14 800 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrKd1)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект КД-002</h3>
                        <p className={styles.cell_text}>{house} 11.9х10.2 м.</p>
                        <p className={styles.cell_number}>от 9 680 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrKd2)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект КД-003</h3>
                        <p className={styles.cell_text}>{house} 10.4х7.4 м.</p>
                        <p className={styles.cell_number}>от 6 160 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrKd3)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_4} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект КД-004</h3>
                        <p className={styles.cell_text}>{house} 10.2х9.2 м.</p>
                        <p className={styles.cell_number}>от 7 520 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrKd4)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default DomaKirpichnye