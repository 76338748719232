import React from 'react'
import styles from './Footer.module.scss'
import logo from '../../image/Header/logo.svg'
import ButtonsFour from '../ButtonsFour/ButtonsFour'
import yt from '../../image/Footer/youtube_big.svg'

const Footer = () => {

    return (
        <footer className={styles.main} id='kontakty'>
            <div className={styles.container}>
                <div className={styles.box}>
                    <img className={styles.logo} src={logo} alt = 'логотип'/>
                    <div className={styles.box_mail}>
                        <a className={styles.link_mail}  href="tel:+79161705333" target='_blank' rel='noopener noreferrer'>+7 916 <span>1705333</span></a>
                    </div>
                    <ButtonsFour/>
                    <div className={styles.box_yt}>
                        <p className={styles.text}>Подписывайтесь на наш канал:</p>
                        <a className={styles.link_yt} target='_blank' rel='noopener noreferrer' href=' https://youtube.com/@MirStroitelstva02?si=2HKFHDhvc6yyRhdq' aria-label='ютуб канал'>
                            <img className={styles.icon_yt} src={yt} alt = 'icon'/>
                        </a>
                    </div>
                </div>
                <div className={styles.box}>
                    <p className={styles.text}>Обращаем ваше внимание на то, что данный интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации. Копирование материалов сайта запрещено.</p>
                    <p className={styles.text}>&#169; 2024 Все права защищены.</p>
                    <a className={styles.link_developer} target='_blank' rel='noopener noreferrer' href='https://tihonov-studio.ru/' aria-label='сайт тихонова дмитрия'>
                        <p className={styles.text}>Разработано<span> TD</span></p>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer