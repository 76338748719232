import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './DomaSipPaneli.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'

import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/House/dk-001/img_7.jpg'
import img_2 from '../../image/House/dk-002/img_7.jpg'
import img_3 from '../../image/House/dk-003/img_5.jpg'
import img_5 from '../../image/House/dk-005/img_5.jpg'
import img_6 from '../../image/House/dk-006/img_5.jpg'
import img_8 from '../../image/House/dk-008/img_5.jpg'
import img_9 from '../../image/House/dk-009/img_5.jpg'
import img_10 from '../../image/House/dk-010/img_5.jpg'
import img_11 from '../../image/House/dk-011/img_5.jpg'
import img_12 from '../../image/House/dk-012/img_5.jpg'
import img_13 from '../../image/House/dk-013/img_5.jpg'
import img_14 from '../../image/House/dk-014/img_5.jpg'
import img_15 from '../../image/House/dk-015/img_5.jpg'
import img_16 from '../../image/House/dk-016/img_5.jpg'
import img_17 from '../../image/House/dk-017/img_5.jpg'
import img_18 from '../../image/House/dk-018/img_5.jpg'
import img_19 from '../../image/House/dk-019/img_5.jpg'
import img_20 from '../../image/House/dk-020/img_5.jpg'
import img_22 from '../../image/House/dk-022/img_5.jpg'
import img_23 from '../../image/House/dk-023/img_5.jpg'
import img_24 from '../../image/House/dk-024/img_5.jpg'
import img_25 from '../../image/House/dk-025/img_5.jpg'
import img_26 from '../../image/House/dk-026/img_5.jpg'
import img_29 from '../../image/House/dk-029/img_5.jpg'
import img_30 from '../../image/House/dk-030/img_5.jpg'
import img_31 from '../../image/House/dk-031/img_5.jpg'
import img_35 from '../../image/House/dk-035/img_5.jpg'
import img_36 from '../../image/House/dk-036/img_5.jpg'
import img_38 from '../../image/House/dk-038/img_5.jpg'
import img_39 from '../../image/House/dk-039/img_5.jpg'
import img_40 from '../../image/House/dk-040/img_5.jpg'
import img_41 from '../../image/House/dk-041/img_5.jpg'
import img_42 from '../../image/House/dk-042/img_5.jpg'
import img_44 from '../../image/House/dk-044/img_5.jpg'
import img_45 from '../../image/House/dk-045/img_5.jpg'
import img_46 from '../../image/House/dk-046/img_5.jpg'
import img_47 from '../../image/House/dk-047/img_5.jpg'
import img_49 from '../../image/House/dk-049/img_5.jpg'
import img_50 from '../../image/House/dk-050/img_5.jpg'
import img_302 from '../../image/House/dk-302/img_5.jpg'
import img_303 from '../../image/House/dk-303/img_5.jpg'
import img_304 from '../../image/House/dk-304/img_5.jpg'
import img_305 from '../../image/House/dk-305/img_5.jpg'
import img_307 from '../../image/House/dk-307/img_5.jpg'
import img_310 from '../../image/House/dk-310/img_5.jpg'
import img_316 from '../../image/House/dk-316/img_5.jpg'
import img_319 from '../../image/House/dk-319/img_5.jpg'
import img_320 from '../../image/House/dk-320/img_5.jpg'
import img_602 from '../../image/House/dk-602/img_5.jpg'
import img_606 from '../../image/House/dk-606/img_5.jpg'

import { arrDk1s } from '../../utils/dk'
import { arrDk2s } from '../../utils/dk'
import { arrDk3s } from '../../utils/dk'
import { arrDk5s } from '../../utils/dk'
import { arrDk6s } from '../../utils/dk'
import { arrDk8s } from '../../utils/dk'
import { arrDk9s } from '../../utils/dk'
import { arrDk10s } from '../../utils/dk'
import { arrDk11s } from '../../utils/dk'
import { arrDk12s } from '../../utils/dk'
import { arrDk13s } from '../../utils/dk'
import { arrDk14s } from '../../utils/dk'
import { arrDk15s } from '../../utils/dk'
import { arrDk16s } from '../../utils/dk'
import { arrDk17s } from '../../utils/dk'
import { arrDk18s } from '../../utils/dk'
import { arrDk19s } from '../../utils/dk'
import { arrDk20s } from '../../utils/dk'
import { arrDk22s } from '../../utils/dk'
import { arrDk23s } from '../../utils/dk'
import { arrDk24s } from '../../utils/dk'
import { arrDk25s } from '../../utils/dk'
import { arrDk26s } from '../../utils/dk'
import { arrDk29s } from '../../utils/dk'
import { arrDk30s } from '../../utils/dk'
import { arrDk31s } from '../../utils/dk'
import { arrDk35s } from '../../utils/dk'
import { arrDk36s } from '../../utils/dk'
import { arrDk38s } from '../../utils/dk'
import { arrDk39s } from '../../utils/dk'
import { arrDk40s } from '../../utils/dk'
import { arrDk41s } from '../../utils/dk'
import { arrDk42s } from '../../utils/dk'
import { arrDk44s } from '../../utils/dk'
import { arrDk45s } from '../../utils/dk'
import { arrDk46s } from '../../utils/dk'
import { arrDk47s } from '../../utils/dk'
import { arrDk49s } from '../../utils/dk'
import { arrDk50s } from '../../utils/dk'
import { arrDk302s } from '../../utils/dk'
import { arrDk303s } from '../../utils/dk'
import { arrDk304s } from '../../utils/dk'
import { arrDk305s } from '../../utils/dk'
import { arrDk307s } from '../../utils/dk'
import { arrDk310s } from '../../utils/dk'
import { arrDk316s } from '../../utils/dk'
import { arrDk319s } from '../../utils/dk'
import { arrDk320s } from '../../utils/dk'
import { arrDk602s } from '../../utils/dk'
import { arrDk606s } from '../../utils/dk'
import { arrPrise } from '../../utils/dk'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const DomaSipPaneli = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const house = 'Дом из сип панелей';

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Дома сип панели'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.title}>Готовые проекты<span> домов из сип панелей</span></h2>
                    <ul className={styles.list}>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-001</h3>
                        <p className={styles.cell_text}>{house} 7х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[0]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk1s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-002</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[1]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk2s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-003</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[2]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk3s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_5} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-005</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[3]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk5s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_6} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-006</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[4]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk6s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_8} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-008</h3>
                        <p className={styles.cell_text}>{house} 6х5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[5]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk8s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_9} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-009</h3>
                        <p className={styles.cell_text}>{house} 6х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[6]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk9s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_10} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-010</h3>
                        <p className={styles.cell_text}>{house} 6х4 м.</p>
                        <p className={styles.cell_number}>{arrPrise[7]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk10s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_11} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-011</h3>
                        <p className={styles.cell_text}>{house} 8х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[8]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk11s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_12} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-012</h3>
                        <p className={styles.cell_text}>{house} 9х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[9]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk12s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_13} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-013</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[10]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk13s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_14} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-014</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[11]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk14s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_15} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-015</h3>
                        <p className={styles.cell_text}>{house} 9.5х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[12]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk15s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_16} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-016</h3>
                        <p className={styles.cell_text}>{house} 10х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[13]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk16s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_17} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-017</h3>
                        <p className={styles.cell_text}>{house} 7.2х10 м.</p>
                        <p className={styles.cell_number}>{arrPrise[14]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk17s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_18} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-018</h3>
                        <p className={styles.cell_text}>{house} 9.5х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[15]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk18s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_19} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-019</h3>
                        <p className={styles.cell_text}>{house} 13.5х9.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[16]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk19s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_20} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-020</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[17]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk20s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_22} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-022</h3>
                        <p className={styles.cell_text}>{house} 12х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[18]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk22s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_23} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-023</h3>
                        <p className={styles.cell_text}>{house} 9.5х6.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[19]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk23s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_24} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-024</h3>
                        <p className={styles.cell_text}>{house} 9х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[20]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk24s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_25} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-025</h3>
                        <p className={styles.cell_text}>{house} 10.5х7.7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[21]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk25s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_26} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-026</h3>
                        <p className={styles.cell_text}>{house} 5х10 м.</p>
                        <p className={styles.cell_number}>{arrPrise[22]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk26s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_29} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-029</h3>
                        <p className={styles.cell_text}>{house} 8х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[23]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk29s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_30} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-030</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[24]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk30s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_31} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-031</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[25]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk31s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_35} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-035</h3>
                        <p className={styles.cell_text}>{house} 9х8.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[26]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk35s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_36} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-036</h3>
                        <p className={styles.cell_text}>{house} 8х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[27]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk36s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_38} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-038</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[28]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk38s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_39} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-039</h3>
                        <p className={styles.cell_text}>{house} 12х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[29]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk39s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_40} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-040</h3>
                        <p className={styles.cell_text}>{house} 11х10.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[30]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk40s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_41} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-041</h3>
                        <p className={styles.cell_text}>{house} 12х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[31]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk41s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_42} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-042</h3>
                        <p className={styles.cell_text}>{house} 10х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[32]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk42s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_44} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-044</h3>
                        <p className={styles.cell_text}>{house} 8х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[33]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk44s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_45} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-045</h3>
                        <p className={styles.cell_text}>{house} 13х10.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[34]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk45s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_46} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-046</h3>
                        <p className={styles.cell_text}>{house} 8х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[35]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk46s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_47} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-047</h3>
                        <p className={styles.cell_text}>{house} 9х11 м.</p>
                        <p className={styles.cell_number}>{arrPrise[36]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk47s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_49} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-049</h3>
                        <p className={styles.cell_text}>{house} 12х11 м.</p>
                        <p className={styles.cell_number}>{arrPrise[37]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk49s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_50} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-050</h3>
                        <p className={styles.cell_text}>{house} 12х11.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[38]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk50s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_302} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-302</h3>
                        <p className={styles.cell_text}>{house} 9х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[39]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk302s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_303} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-303</h3>
                        <p className={styles.cell_text}>{house} 10х7 м.</p>
                        <p className={styles.cell_number}>{arrPrise[40]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk303s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_304} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-304</h3>
                        <p className={styles.cell_text}>{house} 4х6 м.</p>
                        <p className={styles.cell_number}>{arrPrise[41]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk304s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_305} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-305</h3>
                        <p className={styles.cell_text}>{house} 6х8 м.</p>
                        <p className={styles.cell_number}>{arrPrise[42]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk305s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_307} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-307</h3>
                        <p className={styles.cell_text}>{house} 10х8.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[43]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk307s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_310} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-310</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[44]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk310s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_316} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-316</h3>
                        <p className={styles.cell_text}>{house} 12х10 м.</p>
                        <p className={styles.cell_number}>{arrPrise[45]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk316s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_319} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-319</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[46]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk319s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_320} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-320</h3>
                        <p className={styles.cell_text}>{house} 9х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[47]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk320s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_602} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-602</h3>
                        <p className={styles.cell_text}>{house} 12х7.5 м.</p>
                        <p className={styles.cell_number}>{arrPrise[48]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk602s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_606} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект ДК-606</h3>
                        <p className={styles.cell_text}>{house} 9х9 м.</p>
                        <p className={styles.cell_number}>{arrPrise[49]} ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={DkClick => { isImageOpen(arrDk606s)}}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                </div>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default DomaSipPaneli