
import mb1_1 from '../image/MobilnyeBani/mb-001/img_1.jpg'
import mb1_2 from '../image/MobilnyeBani/mb-002/img_2.jpg'
import mb1_3 from '../image/MobilnyeBani/mb-001/img_3.jpg'
import mb1_4 from '../image/MobilnyeBani/mb-001/img_4.jpg'
import mb1_5 from '../image/MobilnyeBani/mb-001/img_5.jpg'

import mb2_1 from '../image/MobilnyeBani/mb-002/img_1.jpg'
import mb2_2 from '../image/MobilnyeBani/mb-002/img_2.jpg'
import mb2_3 from '../image/MobilnyeBani/mb-002/img_3.jpg'
import mb2_4 from '../image/MobilnyeBani/mb-002/img_4.jpg'
import mb2_5 from '../image/MobilnyeBani/mb-002/img_5.jpg'

import mb3_1 from '../image/MobilnyeBani/mb-003/img_1.jpg'
import mb3_2 from '../image/MobilnyeBani/mb-003/img_2.jpg'
import mb3_3 from '../image/MobilnyeBani/mb-003/img_3.jpg'
import mb3_4 from '../image/MobilnyeBani/mb-003/img_4.jpg'
import mb3_5 from '../image/MobilnyeBani/mb-003/img_5.jpg'

import mb4_1 from '../image/MobilnyeBani/mb-004/img_1.jpg'
import mb4_2 from '../image/MobilnyeBani/mb-004/img_2.jpg'
import mb4_3 from '../image/MobilnyeBani/mb-004/img_3.jpg'
import mb4_4 from '../image/MobilnyeBani/mb-004/img_4.jpg'
import mb4_5 from '../image/MobilnyeBani/mb-004/img_5.jpg'

import mb5_1 from '../image/MobilnyeBani/mb-005/img_1.jpg'
import mb5_2 from '../image/MobilnyeBani/mb-005/img_2.jpg'
import mb5_3 from '../image/MobilnyeBani/mb-005/img_3.jpg'
import mb5_4 from '../image/MobilnyeBani/mb-005/img_4.jpg'
import mb5_5 from '../image/MobilnyeBani/mb-005/img_5.jpg'

import mb6_1 from '../image/MobilnyeBani/mb-006/img_1.jpg'
import mb6_2 from '../image/MobilnyeBani/mb-006/img_2.jpg'
import mb6_3 from '../image/MobilnyeBani/mb-006/img_3.jpg'
import mb6_4 from '../image/MobilnyeBani/mb-006/img_4.jpg'
import mb6_5 from '../image/MobilnyeBani/mb-006/img_5.jpg'

import mb7_1 from '../image/MobilnyeBani/mb-007/img_1.jpg'
import mb7_2 from '../image/MobilnyeBani/mb-007/img_2.jpg'
import mb7_3 from '../image/MobilnyeBani/mb-007/img_3.jpg'
import mb7_4 from '../image/MobilnyeBani/mb-007/img_4.jpg'
import mb7_5 from '../image/MobilnyeBani/mb-007/img_5.jpg'

import mb8_1 from '../image/MobilnyeBani/mb-008/img_1.jpg'
import mb8_2 from '../image/MobilnyeBani/mb-008/img_2.jpg'
import mb8_3 from '../image/MobilnyeBani/mb-008/img_3.jpg'
import mb8_4 from '../image/MobilnyeBani/mb-008/img_4.jpg'
import mb8_5 from '../image/MobilnyeBani/mb-008/img_5.jpg'

import mb9_1 from '../image/MobilnyeBani/mb-009/img_1.jpg'
import mb9_2 from '../image/MobilnyeBani/mb-009/img_2.jpg'
import mb9_3 from '../image/MobilnyeBani/mb-009/img_3.jpg'
import mb9_4 from '../image/MobilnyeBani/mb-009/img_4.jpg'
import mb9_5 from '../image/MobilnyeBani/mb-009/img_5.jpg'

import mb10_1 from '../image/MobilnyeBani/mb-010/img_1.jpg'
import mb10_2 from '../image/MobilnyeBani/mb-010/img_2.jpg'
import mb10_3 from '../image/MobilnyeBani/mb-010/img_3.jpg'
import mb10_4 from '../image/MobilnyeBani/mb-010/img_4.jpg'
import mb10_5 from '../image/MobilnyeBani/mb-010/img_5.jpg'

import mb11_1 from '../image/MobilnyeBani/mb-011/img_1.jpg'
import mb11_2 from '../image/MobilnyeBani/mb-011/img_2.jpg'
import mb11_3 from '../image/MobilnyeBani/mb-011/img_3.jpg'
import mb11_4 from '../image/MobilnyeBani/mb-011/img_4.jpg'
import mb11_5 from '../image/MobilnyeBani/mb-011/img_5.jpg'

import mb12_1 from '../image/MobilnyeBani/mb-012/img_1.jpg'
import mb12_2 from '../image/MobilnyeBani/mb-012/img_2.jpg'
import mb12_3 from '../image/MobilnyeBani/mb-012/img_3.jpg'
import mb12_4 from '../image/MobilnyeBani/mb-012/img_4.jpg'
import mb12_5 from '../image/MobilnyeBani/mb-012/img_5.jpg'

import mb13_1 from '../image/MobilnyeBani/mb-013/img_1.jpg'
import mb13_2 from '../image/MobilnyeBani/mb-013/img_2.jpg'
import mb13_3 from '../image/MobilnyeBani/mb-013/img_3.jpg'
import mb13_4 from '../image/MobilnyeBani/mb-013/img_4.jpg'
import mb13_5 from '../image/MobilnyeBani/mb-013/img_5.jpg'

import mb14_1 from '../image/MobilnyeBani/mb-014/img_1.jpg'
import mb14_2 from '../image/MobilnyeBani/mb-014/img_2.jpg'
import mb14_3 from '../image/MobilnyeBani/mb-014/img_3.jpg'
import mb14_4 from '../image/MobilnyeBani/mb-014/img_4.jpg'
import mb14_5 from '../image/MobilnyeBani/mb-014/img_5.jpg'

import mb15_1 from '../image/MobilnyeBani/mb-015/img_1.jpg'
import mb15_2 from '../image/MobilnyeBani/mb-015/img_2.jpg'
import mb15_3 from '../image/MobilnyeBani/mb-015/img_3.jpg'
import mb15_4 from '../image/MobilnyeBani/mb-015/img_4.jpg'
import mb15_5 from '../image/MobilnyeBani/mb-015/img_5.jpg'

import mb18_1 from '../image/MobilnyeBani/mb-018/img_1.jpg'
import mb18_2 from '../image/MobilnyeBani/mb-018/img_2.jpg'
import mb18_3 from '../image/MobilnyeBani/mb-018/img_3.jpg'
import mb18_4 from '../image/MobilnyeBani/mb-018/img_4.jpg'
import mb18_5 from '../image/MobilnyeBani/mb-018/img_5.jpg'

import mb19_1 from '../image/MobilnyeBani/mb-019/img_1.jpg'
import mb19_2 from '../image/MobilnyeBani/mb-019/img_2.jpg'
import mb19_3 from '../image/MobilnyeBani/mb-019/img_3.jpg'
import mb19_4 from '../image/MobilnyeBani/mb-019/img_4.jpg'
import mb19_5 from '../image/MobilnyeBani/mb-019/img_5.jpg'

import mb20_1 from '../image/MobilnyeBani/mb-020/img_1.jpg'
import mb20_2 from '../image/MobilnyeBani/mb-020/img_2.jpg'
import mb20_3 from '../image/MobilnyeBani/mb-020/img_3.jpg'
import mb20_4 from '../image/MobilnyeBani/mb-020/img_4.jpg'
import mb20_5 from '../image/MobilnyeBani/mb-020/img_5.jpg'

import mb21_1 from '../image/MobilnyeBani/mb-021/img_1.jpg'
import mb21_2 from '../image/MobilnyeBani/mb-021/img_2.jpg'
import mb21_3 from '../image/MobilnyeBani/mb-021/img_3.jpg'
import mb21_4 from '../image/MobilnyeBani/mb-021/img_4.jpg'
import mb21_5 from '../image/MobilnyeBani/mb-021/img_5.jpg'

import mb22_1 from '../image/MobilnyeBani/mb-022/img_1.jpg'
import mb22_2 from '../image/MobilnyeBani/mb-022/img_2.jpg'
import mb22_3 from '../image/MobilnyeBani/mb-022/img_3.jpg'
import mb22_4 from '../image/MobilnyeBani/mb-022/img_4.jpg'
import mb22_5 from '../image/MobilnyeBani/mb-022/img_5.jpg'

import mb23_1 from '../image/MobilnyeBani/mb-023/img_1.jpg'
import mb23_2 from '../image/MobilnyeBani/mb-023/img_2.jpg'
import mb23_3 from '../image/MobilnyeBani/mb-023/img_3.jpg'
import mb23_4 from '../image/MobilnyeBani/mb-023/img_4.jpg'
import mb23_5 from '../image/MobilnyeBani/mb-023/img_5.jpg'

import mb24_1 from '../image/MobilnyeBani/mb-024/img_1.jpg'
import mb24_2 from '../image/MobilnyeBani/mb-024/img_2.jpg'
import mb24_3 from '../image/MobilnyeBani/mb-024/img_3.jpg'
import mb24_4 from '../image/MobilnyeBani/mb-024/img_4.jpg'
import mb24_5 from '../image/MobilnyeBani/mb-024/img_5.jpg'

import mb25_1 from '../image/MobilnyeBani/mb-025/img_1.jpg'
import mb25_2 from '../image/MobilnyeBani/mb-025/img_2.jpg'
import mb25_3 from '../image/MobilnyeBani/mb-025/img_3.jpg'
import mb25_4 from '../image/MobilnyeBani/mb-025/img_4.jpg'
import mb25_5 from '../image/MobilnyeBani/mb-025/img_5.jpg'

import mb26_1 from '../image/MobilnyeBani/mb-026/img_1.jpg'
import mb26_2 from '../image/MobilnyeBani/mb-026/img_2.jpg'
import mb26_3 from '../image/MobilnyeBani/mb-026/img_3.jpg'
import mb26_4 from '../image/MobilnyeBani/mb-026/img_4.jpg'
import mb26_5 from '../image/MobilnyeBani/mb-026/img_5.jpg'


export const arrMb1 = [{image: mb1_1, title: 'баня'}, {image: mb1_2, title: 'баня'}, {image: mb1_3, title: 'баня'}, {image: mb1_4, title: 'баня'}, {image: mb1_5, title: 'баня'},];

export const arrMb2 = [{image: mb2_1, title: 'баня'}, {image: mb2_2, title: 'баня'}, {image: mb2_3, title: 'баня'}, {image: mb2_4, title: 'баня'}, {image: mb2_5, title: 'баня'},];

export const arrMb3 = [{image: mb3_1, title: 'баня'}, {image: mb3_2, title: 'баня'}, {image: mb3_3, title: 'баня'}, {image: mb3_4, title: 'баня'}, {image: mb3_5, title: 'баня'},];

export const arrMb4 = [{image: mb4_1, title: 'баня'}, {image: mb4_2, title: 'баня'}, {image: mb4_3, title: 'баня'}, {image: mb4_4, title: 'баня'}, {image: mb4_5, title: 'баня'},];

export const arrMb5 = [{image: mb5_1, title: 'баня'}, {image: mb5_2, title: 'баня'}, {image: mb5_3, title: 'баня'}, {image: mb5_4, title: 'баня'}, {image: mb5_5, title: 'баня'},];

export const arrMb6 = [{image: mb6_1, title: 'баня'}, {image: mb6_2, title: 'баня'}, {image: mb6_3, title: 'баня'}, {image: mb6_4, title: 'баня'}, {image: mb6_5, title: 'баня'},];

export const arrMb7 = [{image: mb7_1, title: 'баня'}, {image: mb7_2, title: 'баня'}, {image: mb7_3, title: 'баня'}, {image: mb7_4, title: 'баня'}, {image: mb7_5, title: 'баня'},];

export const arrMb8 = [{image: mb8_1, title: 'баня'}, {image: mb8_2, title: 'баня'}, {image: mb8_3, title: 'баня'}, {image: mb8_4, title: 'баня'}, {image: mb8_5, title: 'баня'},];

export const arrMb9 = [{image: mb9_1, title: 'баня'}, {image: mb9_2, title: 'баня'}, {image: mb9_3, title: 'баня'}, {image: mb9_4, title: 'баня'}, {image: mb9_5, title: 'баня'},];

export const arrMb10 = [{image: mb10_1, title: 'баня'}, {image: mb10_2, title: 'баня'}, {image: mb10_3, title: 'баня'}, {image: mb10_4, title: 'баня'}, {image: mb10_5, title: 'баня'},];

export const arrMb11 = [{image: mb11_1, title: 'баня'}, {image: mb11_2, title: 'баня'}, {image: mb11_3, title: 'баня'}, {image: mb11_4, title: 'баня'}, {image: mb11_5, title: 'баня'},];

export const arrMb12 = [{image: mb12_1, title: 'баня'}, {image: mb12_2, title: 'баня'}, {image: mb12_3, title: 'баня'}, {image: mb12_4, title: 'баня'}, {image: mb12_5, title: 'баня'},];

export const arrMb13 = [{image: mb13_1, title: 'баня'}, {image: mb13_2, title: 'баня'}, {image: mb13_3, title: 'баня'}, {image: mb13_4, title: 'баня'}, {image: mb13_5, title: 'баня'},];

export const arrMb14 = [{image: mb14_1, title: 'баня'}, {image: mb14_2, title: 'баня'}, {image: mb14_3, title: 'баня'}, {image: mb14_4, title: 'баня'}, {image: mb14_5, title: 'баня'},];

export const arrMb15 = [{image: mb15_1, title: 'баня'}, {image: mb15_2, title: 'баня'}, {image: mb15_3, title: 'баня'}, {image: mb15_4, title: 'баня'}, {image: mb15_5, title: 'баня'},];

export const arrMb18 = [{image: mb18_1, title: 'баня'}, {image: mb18_2, title: 'баня'}, {image: mb18_3, title: 'баня'}, {image: mb18_4, title: 'баня'}, {image: mb18_5, title: 'баня'},];

export const arrMb19 = [{image: mb19_1, title: 'баня'}, {image: mb19_2, title: 'баня'}, {image: mb19_3, title: 'баня'}, {image: mb19_4, title: 'баня'}, {image: mb19_5, title: 'баня'},];

export const arrMb20 = [{image: mb20_1, title: 'баня'}, {image: mb20_2, title: 'баня'}, {image: mb20_3, title: 'баня'}, {image: mb20_4, title: 'баня'}, {image: mb20_5, title: 'баня'},];

export const arrMb21 = [{image: mb21_1, title: 'баня'}, {image: mb21_2, title: 'баня'}, {image: mb21_3, title: 'баня'}, {image: mb21_4, title: 'баня'}, {image: mb21_5, title: 'баня'},];

export const arrMb22 = [{image: mb22_1, title: 'баня'}, {image: mb22_2, title: 'баня'}, {image: mb22_3, title: 'баня'}, {image: mb22_4, title: 'баня'}, {image: mb22_5, title: 'баня'},];

export const arrMb23 = [{image: mb23_1, title: 'баня'}, {image: mb23_2, title: 'баня'}, {image: mb23_3, title: 'баня'}, {image: mb23_4, title: 'баня'}, {image: mb23_5, title: 'баня'},];

export const arrMb24 = [{image: mb24_1, title: 'баня'}, {image: mb24_2, title: 'баня'}, {image: mb24_3, title: 'баня'}, {image: mb24_4, title: 'баня'}, {image: mb24_5, title: 'баня'},];

export const arrMb25 = [{image: mb25_1, title: 'баня'}, {image: mb25_2, title: 'баня'}, {image: mb25_3, title: 'баня'}, {image: mb25_4, title: 'баня'}, {image: mb25_5, title: 'баня'},];

export const arrMb26 = [{image: mb26_1, title: 'баня'}, {image: mb26_2, title: 'баня'}, {image: mb26_3, title: 'баня'}, {image: mb26_4, title: 'баня'}, {image: mb26_5, title: 'баня'},];


