import bk1_1 from '../image/BaniBrus/bk-1/img_1.jpg'
import bk1_2 from '../image/BaniBrus/bk-1/img_2.jpg'
import bk1_3 from '../image/BaniBrus/bk-1/img_3.jpg'
import bk1_4 from '../image/BaniBrus/bk-1/img_4.jpg'
import bk1_5 from '../image/BaniBrus/bk-1/img_5.jpg'
import bk1_6 from '../image/BaniBrus/bk-1/img_6.jpg'
import bk1_7 from '../image/BaniBrus/bk-1/img_7.jpg'
import bk1_8 from '../image/BaniBrus/bk-1/img_8.jpg'
import bk1_9 from '../image/BaniBrus/bk-1/img_9.jpg'
///////////////
import bk2_1 from '../image/BaniBrus/bk-2/img_1.jpg'
import bk2_2 from '../image/BaniBrus/bk-2/img_2.jpg'
import bk2_3 from '../image/BaniBrus/bk-2/img_3.jpg'
import bk2_4 from '../image/BaniBrus/bk-2/img_4.jpg'
import bk2_5 from '../image/BaniBrus/bk-2/img_5.jpg'
import bk2_6 from '../image/BaniBrus/bk-2/img_6.jpg'
import bk2_7 from '../image/BaniBrus/bk-2/img_7.jpg'
import bk2_8 from '../image/BaniBrus/bk-2/img_8.jpg'
import bk2_9 from '../image/BaniBrus/bk-2/img_9.jpg'
/////////////
import bk3_1 from '../image/BaniBrus/bk-3/img_1.jpg'
import bk3_2 from '../image/BaniBrus/bk-3/img_2.jpg'
import bk3_3 from '../image/BaniBrus/bk-3/img_3.jpg'
import bk3_4 from '../image/BaniBrus/bk-3/img_4.jpg'
import bk3_5 from '../image/BaniBrus/bk-3/img_5.jpg'
import bk3_6 from '../image/BaniBrus/bk-3/img_6.jpg'
import bk3_7 from '../image/BaniBrus/bk-3/img_7.jpg'
import bk3_8 from '../image/BaniBrus/bk-3/img_8.jpg'
import bk3_9 from '../image/BaniBrus/bk-3/img_9.jpg'
/////////////
import bk4_1 from '../image/BaniBrus/bk-4/img_1.jpg'
import bk4_2 from '../image/BaniBrus/bk-4/img_2.jpg'
import bk4_3 from '../image/BaniBrus/bk-4/img_3.jpg'
import bk4_4 from '../image/BaniBrus/bk-4/img_4.jpg'
import bk4_5 from '../image/BaniBrus/bk-4/img_5.jpg'
import bk4_6 from '../image/BaniBrus/bk-4/img_6.jpg'
import bk4_7 from '../image/BaniBrus/bk-4/img_7.jpg'
import bk4_8 from '../image/BaniBrus/bk-4/img_8.jpg'
import bk4_9 from '../image/BaniBrus/bk-4/img_9.jpg'
/////////////
import bk5_1 from '../image/BaniBrus/bk-5/img_1.jpg'
import bk5_2 from '../image/BaniBrus/bk-5/img_2.jpg'
import bk5_3 from '../image/BaniBrus/bk-5/img_3.jpg'
import bk5_4 from '../image/BaniBrus/bk-5/img_4.jpg'
import bk5_5 from '../image/BaniBrus/bk-5/img_5.jpg'
import bk5_6 from '../image/BaniBrus/bk-5/img_6.jpg'
import bk5_7 from '../image/BaniBrus/bk-5/img_7.jpg'
import bk5_8 from '../image/BaniBrus/bk-5/img_8.jpg'
import bk5_9 from '../image/BaniBrus/bk-5/img_9.jpg'
/////////////
import bk6_1 from '../image/BaniBrus/bk-6/img_1.jpg'
import bk6_2 from '../image/BaniBrus/bk-6/img_2.jpg'
import bk6_3 from '../image/BaniBrus/bk-6/img_3.jpg'
import bk6_4 from '../image/BaniBrus/bk-6/img_4.jpg'
import bk6_5 from '../image/BaniBrus/bk-6/img_5.jpg'
import bk6_6 from '../image/BaniBrus/bk-6/img_6.jpg'
import bk6_7 from '../image/BaniBrus/bk-6/img_7.jpg'
import bk6_8 from '../image/BaniBrus/bk-6/img_8.jpg'
import bk6_9 from '../image/BaniBrus/bk-6/img_9.jpg'
/////////////
import bk10_1 from '../image/BaniBrus/bk-10/img_1.jpg'
import bk10_2 from '../image/BaniBrus/bk-10/img_2.jpg'
import bk10_3 from '../image/BaniBrus/bk-10/img_3.jpg'
import bk10_4 from '../image/BaniBrus/bk-10/img_4.jpg'
import bk10_5 from '../image/BaniBrus/bk-10/img_5.jpg'
import bk10_6 from '../image/BaniBrus/bk-10/img_6.jpg'
import bk10_7 from '../image/BaniBrus/bk-10/img_7.jpg'
import bk10_8 from '../image/BaniBrus/bk-10/img_8.jpg'
import bk10_9 from '../image/BaniBrus/bk-10/img_9.jpg'
/////////////
import bk13_1 from '../image/BaniBrus/bk-13/img_1.jpg'
import bk13_2 from '../image/BaniBrus/bk-13/img_2.jpg'
import bk13_3 from '../image/BaniBrus/bk-13/img_3.jpg'
import bk13_4 from '../image/BaniBrus/bk-13/img_4.jpg'
import bk13_5 from '../image/BaniBrus/bk-13/img_5.jpg'
import bk13_6 from '../image/BaniBrus/bk-13/img_6.jpg'
import bk13_7 from '../image/BaniBrus/bk-13/img_7.jpg'
import bk13_8 from '../image/BaniBrus/bk-13/img_8.jpg'
import bk13_9 from '../image/BaniBrus/bk-13/img_9.jpg'
/////////////
import bk14_1 from '../image/BaniBrus/bk-14/img_1.jpg'
import bk14_2 from '../image/BaniBrus/bk-14/img_2.jpg'
import bk14_3 from '../image/BaniBrus/bk-14/img_3.jpg'
import bk14_4 from '../image/BaniBrus/bk-14/img_4.jpg'
import bk14_5 from '../image/BaniBrus/bk-14/img_5.jpg'
import bk14_6 from '../image/BaniBrus/bk-14/img_6.jpg'
import bk14_7 from '../image/BaniBrus/bk-14/img_7.jpg'
import bk14_8 from '../image/BaniBrus/bk-14/img_8.jpg'
import bk14_9 from '../image/BaniBrus/bk-14/img_9.jpg'
/////////////
import bk15_1 from '../image/BaniBrus/bk-15/img_1.jpg'
import bk15_2 from '../image/BaniBrus/bk-15/img_2.jpg'
import bk15_3 from '../image/BaniBrus/bk-15/img_3.jpg'
import bk15_4 from '../image/BaniBrus/bk-15/img_4.jpg'
import bk15_5 from '../image/BaniBrus/bk-15/img_5.jpg'
import bk15_6 from '../image/BaniBrus/bk-15/img_6.jpg'
import bk15_7 from '../image/BaniBrus/bk-15/img_7.jpg'
import bk15_8 from '../image/BaniBrus/bk-15/img_8.jpg'
import bk15_9 from '../image/BaniBrus/bk-15/img_9.jpg'
/////////////
import bk19_1 from '../image/BaniBrus/bk-19/img_1.jpg'
import bk19_2 from '../image/BaniBrus/bk-19/img_2.jpg'
import bk19_3 from '../image/BaniBrus/bk-19/img_3.jpg'
import bk19_4 from '../image/BaniBrus/bk-19/img_4.jpg'
import bk19_5 from '../image/BaniBrus/bk-19/img_5.jpg'
import bk19_6 from '../image/BaniBrus/bk-19/img_6.jpg'
import bk19_7 from '../image/BaniBrus/bk-19/img_7.jpg'
import bk19_8 from '../image/BaniBrus/bk-19/img_8.jpg'
import bk19_9 from '../image/BaniBrus/bk-19/img_9.jpg'
/////////////
import bk22_1 from '../image/BaniBrus/bk-22/img_1.jpg'
import bk22_2 from '../image/BaniBrus/bk-22/img_2.jpg'
import bk22_3 from '../image/BaniBrus/bk-22/img_3.jpg'
import bk22_4 from '../image/BaniBrus/bk-22/img_4.jpg'
import bk22_5 from '../image/BaniBrus/bk-22/img_5.jpg'
import bk22_6 from '../image/BaniBrus/bk-22/img_6.jpg'
import bk22_7 from '../image/BaniBrus/bk-22/img_7.jpg'
import bk22_8 from '../image/BaniBrus/bk-22/img_8.jpg'
import bk22_9 from '../image/BaniBrus/bk-22/img_9.jpg'
/////////////
import bk23_1 from '../image/BaniBrus/bk-23/img_1.jpg'
import bk23_2 from '../image/BaniBrus/bk-23/img_2.jpg'
import bk23_3 from '../image/BaniBrus/bk-23/img_3.jpg'
import bk23_4 from '../image/BaniBrus/bk-23/img_4.jpg'
import bk23_5 from '../image/BaniBrus/bk-23/img_5.jpg'
import bk23_6 from '../image/BaniBrus/bk-23/img_6.jpg'
import bk23_7 from '../image/BaniBrus/bk-23/img_7.jpg'
import bk23_8 from '../image/BaniBrus/bk-23/img_8.jpg'
import bk23_9 from '../image/BaniBrus/bk-23/img_9.jpg'
/////////////
import bk24_1 from '../image/BaniBrus/bk-24/img_1.jpg'
import bk24_2 from '../image/BaniBrus/bk-24/img_2.jpg'
import bk24_3 from '../image/BaniBrus/bk-24/img_3.jpg'
import bk24_4 from '../image/BaniBrus/bk-24/img_4.jpg'
import bk24_5 from '../image/BaniBrus/bk-24/img_5.jpg'
import bk24_6 from '../image/BaniBrus/bk-24/img_6.jpg'
import bk24_7 from '../image/BaniBrus/bk-24/img_7.jpg'
import bk24_8 from '../image/BaniBrus/bk-24/img_8.jpg'
import bk24_9 from '../image/BaniBrus/bk-24/img_9.jpg'
/////////////
import bk25_1 from '../image/BaniBrus/bk-25/img_1.jpg'
import bk25_2 from '../image/BaniBrus/bk-25/img_2.jpg'
import bk25_3 from '../image/BaniBrus/bk-25/img_3.jpg'
import bk25_4 from '../image/BaniBrus/bk-25/img_4.jpg'
import bk25_5 from '../image/BaniBrus/bk-25/img_5.jpg'
import bk25_6 from '../image/BaniBrus/bk-25/img_6.jpg'
import bk25_7 from '../image/BaniBrus/bk-25/img_7.jpg'
import bk25_8 from '../image/BaniBrus/bk-25/img_8.jpg'
import bk25_9 from '../image/BaniBrus/bk-25/img_9.jpg'
/////////////
import bk26_1 from '../image/BaniBrus/bk-26/img_1.jpg'
import bk26_2 from '../image/BaniBrus/bk-26/img_2.jpg'
import bk26_3 from '../image/BaniBrus/bk-26/img_3.jpg'
import bk26_4 from '../image/BaniBrus/bk-26/img_4.jpg'
import bk26_5 from '../image/BaniBrus/bk-26/img_5.jpg'
import bk26_6 from '../image/BaniBrus/bk-26/img_6.jpg'
import bk26_7 from '../image/BaniBrus/bk-26/img_7.jpg'
import bk26_8 from '../image/BaniBrus/bk-26/img_8.jpg'
import bk26_9 from '../image/BaniBrus/bk-26/img_9.jpg'

export const arrBk1 = [{image: bk1_1, title: 'баня'}, {image: bk1_2, title: 'баня'}, {image: bk1_3, title: 'баня'}, {image: bk1_4, title: 'баня'}, {image: bk1_5, title: 'баня'},];

export const arrBk2 = [{image: bk2_1, title: 'баня'}, {image: bk2_2, title: 'баня'}, {image: bk2_3, title: 'баня'}, {image: bk2_4, title: 'баня'}, {image: bk2_5, title: 'баня'},];

export const arrBk3 = [{image: bk3_1, title: 'баня'}, {image: bk3_2, title: 'баня'}, {image: bk3_3, title: 'баня'}, {image: bk3_4, title: 'баня'}, {image: bk3_5, title: 'баня'},];

export const arrBk4 = [{image: bk4_1, title: 'баня'}, {image: bk4_2, title: 'баня'}, {image: bk4_3, title: 'баня'}, {image: bk4_4, title: 'баня'}, {image: bk4_5, title: 'баня'},];

export const arrBk5 = [{image: bk5_1, title: 'баня'}, {image: bk5_2, title: 'баня'}, {image: bk5_3, title: 'баня'}, {image: bk5_4, title: 'баня'}, {image: bk5_5, title: 'баня'},];

export const arrBk6 = [{image: bk6_1, title: 'баня'}, {image: bk6_2, title: 'баня'}, {image: bk6_3, title: 'баня'}, {image: bk6_4, title: 'баня'}, {image: bk6_5, title: 'баня'},];

export const arrBk10 = [{image: bk10_1, title: 'баня'}, {image: bk10_2, title: 'баня'}, {image: bk10_3, title: 'баня'}, {image: bk10_4, title: 'баня'}, {image: bk10_5, title: 'баня'},];

export const arrBk13 = [{image: bk13_1, title: 'баня'}, {image: bk13_2, title: 'баня'}, {image: bk13_3, title: 'баня'}, {image: bk13_4, title: 'баня'}, {image: bk13_5, title: 'баня'},];

export const arrBk14 = [{image: bk14_1, title: 'баня'}, {image: bk14_2, title: 'баня'}, {image: bk14_3, title: 'баня'}, {image: bk14_4, title: 'баня'}, {image: bk14_5, title: 'баня'},];

export const arrBk15 = [{image: bk15_1, title: 'баня'}, {image: bk15_2, title: 'баня'}, {image: bk15_3, title: 'баня'}, {image: bk15_4, title: 'баня'}, {image: bk15_5, title: 'баня'},];

export const arrBk19 = [{image: bk19_1, title: 'баня'}, {image: bk19_2, title: 'баня'}, {image: bk19_3, title: 'баня'}, {image: bk19_4, title: 'баня'}, {image: bk19_5, title: 'баня'},];

export const arrBk22 = [{image: bk22_1, title: 'баня'}, {image: bk22_2, title: 'баня'}, {image: bk22_3, title: 'баня'}, {image: bk22_4, title: 'баня'}, {image: bk22_5, title: 'баня'},];

export const arrBk23 = [{image: bk23_1, title: 'баня'}, {image: bk23_2, title: 'баня'}, {image: bk23_3, title: 'баня'}, {image: bk23_4, title: 'баня'}, {image: bk23_5, title: 'баня'},];

export const arrBk24 = [{image: bk24_1, title: 'баня'}, {image: bk24_2, title: 'баня'}, {image: bk24_3, title: 'баня'}, {image: bk24_4, title: 'баня'}, {image: bk24_5, title: 'баня'},];

export const arrBk25 = [{image: bk25_1, title: 'баня'}, {image: bk25_2, title: 'баня'}, {image: bk25_3, title: 'баня'}, {image: bk25_4, title: 'баня'}, {image: bk25_5, title: 'баня'},];

export const arrBk26 = [{image: bk26_1, title: 'баня'}, {image: bk26_2, title: 'баня'}, {image: bk26_3, title: 'баня'}, {image: bk26_4, title: 'баня'}, {image: bk26_5, title: 'баня'},];

export const arrBk1w = [{image: bk1_6, title: 'баня'}, {image: bk1_7, title: 'баня'}, {image: bk1_8, title: 'баня'}, {image: bk1_9, title: 'баня'}, {image: bk1_5, title: 'баня'},];