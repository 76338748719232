import kd1_1 from '../image/DomaKirpichnye/kd-001/img_1.jpg'
import kd1_2 from '../image/DomaKirpichnye/kd-001/img_2.jpg'
import kd1_3 from '../image/DomaKirpichnye/kd-001/img_3.jpg'
import kd1_4 from '../image/DomaKirpichnye/kd-001/img_4.jpg'
import kd1_5 from '../image/DomaKirpichnye/kd-001/img_5.png'
import kd1_6 from '../image/DomaKirpichnye/kd-001/img_6.png'

import kd2_1 from '../image/DomaKirpichnye/kd-002/img_1.jpg'
import kd2_2 from '../image/DomaKirpichnye/kd-002/img_2.jpg'
import kd2_3 from '../image/DomaKirpichnye/kd-002/img_3.jpg'
import kd2_4 from '../image/DomaKirpichnye/kd-002/img_4.jpg'
import kd2_5 from '../image/DomaKirpichnye/kd-002/img_5.png'

import kd3_1 from '../image/DomaKirpichnye/kd-003/img_1.jpg'
import kd3_2 from '../image/DomaKirpichnye/kd-003/img_2.jpg'
import kd3_3 from '../image/DomaKirpichnye/kd-003/img_3.jpg'
import kd3_4 from '../image/DomaKirpichnye/kd-003/img_4.jpg'
import kd3_5 from '../image/DomaKirpichnye/kd-003/img_5.png'

import kd4_1 from '../image/DomaKirpichnye/kd-004/img_1.jpg'
import kd4_2 from '../image/DomaKirpichnye/kd-004/img_2.jpg'
import kd4_3 from '../image/DomaKirpichnye/kd-004/img_3.jpg'
import kd4_4 from '../image/DomaKirpichnye/kd-004/img_4.jpg'
import kd4_5 from '../image/DomaKirpichnye/kd-004/img_5.png'

export const arrKd1 = [{image: kd1_1, title: 'баня'}, {image: kd1_2, title: 'баня'}, {image: kd1_3, title: 'баня'}, {image: kd1_4, title: 'баня'}, {image: kd1_5, title: 'баня'}, {image: kd1_6, title: 'баня'},];

export const arrKd2 = [{image: kd2_1, title: 'баня'}, {image: kd2_2, title: 'баня'}, {image: kd2_3, title: 'баня'}, {image: kd2_4, title: 'баня'}, {image: kd2_5, title: 'баня'}, ];

export const arrKd3 = [{image: kd3_1, title: 'баня'}, {image: kd3_2, title: 'баня'}, {image: kd3_3, title: 'баня'}, {image: kd3_4, title: 'баня'}, {image: kd3_5, title: 'баня'}, ];

export const arrKd4 = [{image: kd4_1, title: 'баня'}, {image: kd4_2, title: 'баня'}, {image: kd4_3, title: 'баня'}, {image: kd4_4, title: 'баня'}, {image: kd4_5, title: 'баня'}, ];