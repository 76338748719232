import React, { useState, useCallback, useEffect} from 'react'
import { Route, Routes} from 'react-router-dom'
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import Main  from '../../pages/Main/Main'
import { ModalForm } from '../ModalForm/ModalForm'
import UpButton  from '../UpButton/UpButton'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import Footer  from '../Footer/Footer'
import Contacts  from '../../pages/Contacts/Contacts'
import OurServices from '../../pages/OurServices/OurServices'
import Team from '../../pages/Team/Team'
import Portfolio from '../../pages/Portfolio/Portfolio'
import Modal from '../Modal/Modal'
import ButtonCommunication from '../ButtonCommunication/ButtonCommunication'
import { ModalImage } from '../ModalImage/ModalImage'

import BaniBrus from '../../pages/BaniBrus/BaniBrus'
import DomaKarkasnye from '../../pages/DomaKarkasnye/DomaKarkasnye'
import DomaBrus from '../../pages/DomaBrus/DomaBrus'
import KarkasnyeBani from '../../pages/KarkasnyeBani/KarkasnyeBani'
import DomaKirpichnye from '../../pages/DomaKirpichnye/DomaKirpichnye'
import DomaSipPaneli from '../../pages/DomaSipPaneli/DomaSipPaneli'
import MobilnyeBani from '../../pages/MobilnyeBani/MobilnyeBani'
import DomaFrejm from '../../pages/DomaFrejm/DomaFrejm'



export const App = () =>{

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isArr, setIsArr] = useState(null)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((arr) => {
      setIsPopupImageOpen(true)
      setIsArr(arr)
  }, [])


  return (
    <div className={app.page}>
    <AppHeader
      isOpen={handleOpenMenuMobile}
    />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/proekty'  element={
          <OurServices
            popupOpen={handleOpen}
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/o-nas'  element={
          <Team
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/portfolio'  element={
          <Portfolio
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/kontakty'  element={
          <Contacts
            popupOpen={handleOpen}
          />
        }/>
        <Route path='*' element={
          <PageNotFound/>
                  }/>
        <Route  path='/privacy-policy'  element={
          <PrivacyPolicy/>
        }/>
        <Route  path='/bani-brus'  element={
          <BaniBrus
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/doma-karkasnye'  element={
          <DomaKarkasnye
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/doma-brus'  element={
          <DomaBrus
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/karkasnye-bani'  element={
          <KarkasnyeBani
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/doma-kirpichnye'  element={
          <DomaKirpichnye
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/doma-sip-paneli'  element={
          <DomaSipPaneli
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/mobilnye-bani'  element={
          <MobilnyeBani
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/doma-frejm'  element={
          <DomaFrejm
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>                
      </Routes>
      <Footer/>
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        arr={isArr}
      />}
      <UpButton/>
      <ButtonCommunication/>
    </div>
  )
}