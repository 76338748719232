import po1_1 from '../image/Portfolio/KarkasMytishchi/img_1.jpg'
import po1_2 from '../image/Portfolio/KarkasMytishchi/img_2.jpg'
import po1_3 from '../image/Portfolio/KarkasMytishchi/img_3.jpg'
import po1_4 from '../image/Portfolio/KarkasMytishchi/img_4.jpg'
import po1_5 from '../image/Portfolio/KarkasMytishchi/img_5.jpg'
import po1_6 from '../image/Portfolio/KarkasMytishchi/img_6.jpg'
import po1_7 from '../image/Portfolio/KarkasMytishchi/img_7.jpg'
import po1_8 from '../image/Portfolio/KarkasMytishchi/img_8.jpg'
import po1_9 from '../image/Portfolio/KarkasMytishchi/img_9.jpg'
import po1_10 from '../image/Portfolio/KarkasMytishchi/img_10.jpg'

import po2_1 from '../image/Portfolio/SipSerpuhov/img_1.jpg'
import po2_2 from '../image/Portfolio/SipSerpuhov/img_2.jpg'
import po2_3 from '../image/Portfolio/SipSerpuhov/img_3.jpg'
import po2_4 from '../image/Portfolio/SipSerpuhov/img_4.jpg'
import po2_5 from '../image/Portfolio/SipSerpuhov/img_5.jpg'
import po2_6 from '../image/Portfolio/SipSerpuhov/img_6.jpg'
import po2_7 from '../image/Portfolio/SipSerpuhov/img_7.jpg'
import po2_8 from '../image/Portfolio/SipSerpuhov/img_8.jpg'
import po2_9 from '../image/Portfolio/SipSerpuhov/img_9.jpg'
import po2_10 from '../image/Portfolio/SipSerpuhov/img_10.jpg'

import po3_1 from '../image/Portfolio/SipDmitrov/img_1.jpg'
import po3_2 from '../image/Portfolio/SipDmitrov/img_2.jpg'
import po3_3 from '../image/Portfolio/SipDmitrov/img_3.jpg'
import po3_4 from '../image/Portfolio/SipDmitrov/img_4.jpg'
import po3_5 from '../image/Portfolio/SipDmitrov/img_5.jpg'
import po3_6 from '../image/Portfolio/SipDmitrov/img_6.jpg'
import po3_7 from '../image/Portfolio/SipDmitrov/img_7.jpg'
import po3_8 from '../image/Portfolio/SipDmitrov/img_8.jpg'
import po3_9 from '../image/Portfolio/SipDmitrov/img_9.jpg'
import po3_10 from '../image/Portfolio/SipDmitrov/img_10.jpg'

import po4_1 from '../image/Portfolio/Barnhouse/img_1.jpg'
import po4_2 from '../image/Portfolio/Barnhouse/img_2.jpg'
import po4_3 from '../image/Portfolio/Barnhouse/img_3.jpg'
import po4_4 from '../image/Portfolio/Barnhouse/img_4.jpg'
import po4_5 from '../image/Portfolio/Barnhouse/img_5.jpg'
import po4_6 from '../image/Portfolio/Barnhouse/img_6.jpg'
import po4_7 from '../image/Portfolio/Barnhouse/img_7.jpg'
import po4_8 from '../image/Portfolio/Barnhouse/img_8.jpg'
import po4_9 from '../image/Portfolio/Barnhouse/img_9.jpg'
import po4_10 from '../image/Portfolio/Barnhouse/img_10.jpg'

export const arrPo1 = [{image: po1_1, title: 'баня'}, {image: po1_2, title: 'баня'}, {image: po1_3, title: 'баня'}, {image: po1_4, title: 'баня'}, {image: po1_5, title: 'баня'}, {image: po1_6, title: 'баня'}, {image: po1_7, title: 'баня'}, {image: po1_8, title: 'баня'}, {image: po1_9, title: 'баня'}, {image: po1_10, title: 'баня'},];

export const arrPo2 = [{image: po2_1, title: 'баня'}, {image: po2_2, title: 'баня'}, {image: po2_3, title: 'баня'}, {image: po2_4, title: 'баня'}, {image: po2_5, title: 'баня'}, {image: po2_6, title: 'баня'}, {image: po2_7, title: 'баня'}, {image: po2_8, title: 'баня'}, {image: po2_9, title: 'баня'}, {image: po2_10, title: 'баня'},];

export const arrPo3 = [{image: po3_1, title: 'баня'}, {image: po3_2, title: 'баня'}, {image: po3_3, title: 'баня'}, {image: po3_4, title: 'баня'}, {image: po3_5, title: 'баня'}, {image: po3_6, title: 'баня'}, {image: po3_7, title: 'баня'}, {image: po3_8, title: 'баня'}, {image: po3_9, title: 'баня'}, {image: po3_10, title: 'баня'},];

export const arrPo4 = [{image: po4_1, title: 'баня'}, {image: po4_2, title: 'баня'}, {image: po4_3, title: 'баня'}, {image: po4_4, title: 'баня'}, {image: po4_5, title: 'баня'}, {image: po4_6, title: 'баня'}, {image: po4_7, title: 'баня'}, {image: po4_8, title: 'баня'}, {image: po4_9, title: 'баня'}, {image: po4_10, title: 'баня'},];