import React, {useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './BaniBrus.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import arrow from '../../image/BaniBrus/arrow.svg'
import img_1 from '../../image/BaniBrus/bk-1/img_1.jpg'
import img_2 from '../../image/BaniBrus/bk-2/img_1.jpg'
import img_3 from '../../image/BaniBrus/bk-3/img_1.jpg'
import img_4 from '../../image/BaniBrus/bk-4/img_1.jpg'
import img_5 from '../../image/BaniBrus/bk-5/img_1.jpg'
import img_6 from '../../image/BaniBrus/bk-6/img_1.jpg'
import img_10 from '../../image/BaniBrus/bk-10/img_1.jpg'
import img_13 from '../../image/BaniBrus/bk-13/img_1.jpg'
import img_14 from '../../image/BaniBrus/bk-14/img_1.jpg'
import img_15 from '../../image/BaniBrus/bk-15/img_1.jpg'
import img_19 from '../../image/BaniBrus/bk-19/img_1.jpg'
import img_22 from '../../image/BaniBrus/bk-22/img_1.jpg'
import img_23 from '../../image/BaniBrus/bk-23/img_1.jpg'
import img_24 from '../../image/BaniBrus/bk-24/img_1.jpg'
import img_25 from '../../image/BaniBrus/bk-25/img_1.jpg'
import img_26 from '../../image/BaniBrus/bk-26/img_1.jpg'

///// массивы

import { arrBk1 } from '../../utils/bk'
import { arrBk2 } from '../../utils/bk'
import { arrBk3 } from '../../utils/bk'
import { arrBk4 } from '../../utils/bk'
import { arrBk5 } from '../../utils/bk'
import { arrBk6 } from '../../utils/bk'
import { arrBk10 } from '../../utils/bk'
import { arrBk13 } from '../../utils/bk'
import { arrBk14 } from '../../utils/bk'
import { arrBk15 } from '../../utils/bk'
import { arrBk19 } from '../../utils/bk'
import { arrBk22 } from '../../utils/bk'
import { arrBk23 } from '../../utils/bk'
import { arrBk24 } from '../../utils/bk'
import { arrBk25 } from '../../utils/bk'
import { arrBk26 } from '../../utils/bk'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const BaniBrus = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Bk1Click = useCallback(() => {
        isImageOpen(arrBk1)
    }, [isImageOpen])

    const Bk2Click = useCallback(() => {
        isImageOpen(arrBk2)
    }, [isImageOpen])

    const Bk3Click = useCallback(() => {
        isImageOpen(arrBk3)
    }, [isImageOpen])

    const Bk4Click = useCallback(() => {
        isImageOpen(arrBk4)
    }, [isImageOpen])

    const Bk5Click = useCallback(() => {
        isImageOpen(arrBk5)
    }, [isImageOpen])

    const Bk6Click = useCallback(() => {
        isImageOpen(arrBk6)
    }, [isImageOpen])

    const Bk10Click = useCallback(() => {
        isImageOpen(arrBk10)
    }, [isImageOpen])

    const Bk13Click = useCallback(() => {
        isImageOpen(arrBk13)
    }, [isImageOpen])

    const Bk14Click = useCallback(() => {
        isImageOpen(arrBk14)
    }, [isImageOpen])

    const Bk15Click = useCallback(() => {
        isImageOpen(arrBk15)
    }, [isImageOpen])

    const Bk19Click = useCallback(() => {
        isImageOpen(arrBk19)
    }, [isImageOpen])

    const Bk22Click = useCallback(() => {
        isImageOpen(arrBk22)
    }, [isImageOpen])

    const Bk23Click = useCallback(() => {
        isImageOpen(arrBk23)
    }, [isImageOpen])

    const Bk24Click = useCallback(() => {
        isImageOpen(arrBk24)
    }, [isImageOpen])

    const Bk25Click = useCallback(() => {
        isImageOpen(arrBk25)
    }, [isImageOpen])

    const Bk26Click = useCallback(() => {
        isImageOpen(arrBk26)
    }, [isImageOpen])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <BreadCrumbs
                        text={'Бани из бруса'}
                    />
                <div className={styles.box}>
                    <h2 className={styles.title}>Проекты бань <span>из профилированного бруса</span></h2>
                    <ul className={styles.list}>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_1} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-01</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х4 м.</p>
                        <p className={styles.cell_number}> от 480 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk1Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_2} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-02</h3>
                        <p className={styles.cell_text}>Баня из бруса 5х4 м.</p>
                        <p className={styles.cell_number}>от 400 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk2Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_3} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-03</h3>
                        <p className={styles.cell_text}>Баня из бруса 5.5х4 м.</p>
                        <p className={styles.cell_number}>от 440 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk3Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_4} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-04</h3>
                        <p className={styles.cell_text}>Баня из бруса 8х6 м.</p>
                        <p className={styles.cell_number}>от 960 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk4Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_5} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-05</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х6 м.</p>
                        <p className={styles.cell_number}>от 720 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk5Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_6} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-06</h3>
                        <p className={styles.cell_text}>Баня из бруса 8х4 м.</p>
                        <p className={styles.cell_number}>от 640 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk6Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_10} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-10</h3>
                        <p className={styles.cell_text}>Баня из бруса 9х6 м.</p>
                        <p className={styles.cell_number}>от 1 080 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk10Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_13} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-13</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х6 м.</p>
                        <p className={styles.cell_number}>от 720 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk13Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_14} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-14</h3>
                        <p className={styles.cell_text}>Баня из бруса 7х6.5 м.</p>
                        <p className={styles.cell_number}>от 910 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk14Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_15} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-15</h3>
                        <p className={styles.cell_text}>Баня из бруса 8х6 м.</p>
                        <p className={styles.cell_number}>от 960 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk15Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_19} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-19</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х4 м.</p>
                        <p className={styles.cell_number}>от 360 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk19Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_22} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-22</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х10 м.</p>
                        <p className={styles.cell_number}>от 1 200 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk22Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_23} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-23</h3>
                        <p className={styles.cell_text}>Баня из бруса 5х8 м.</p>
                        <p className={styles.cell_number}>от 800 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk23Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_24} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-24</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х8 м.</p>
                        <p className={styles.cell_number}>от 960 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk24Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_25} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-25</h3>
                        <p className={styles.cell_text}>Баня из бруса 5х5 м.</p>
                        <p className={styles.cell_number}>от 520 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk25Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                    <motion.li
                        className={styles.item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.cell_box}>
                        <img className={styles.cell_image} src={img_26} alt='баня'/>
                    </div>
                    <div className={styles.cell_info}>
                        <h3 className={styles.cell_subtitle}>Проект БК-26</h3>
                        <p className={styles.cell_text}>Баня из бруса 6х8 м.</p>
                        <p className={styles.cell_number}>от 960 000 ₽</p>
                    </div>
                    <span className={styles.cell_arrow} onClick={Bk26Click}>
                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                    </span>
                    </motion.li>
                </ul>
                </div>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default BaniBrus