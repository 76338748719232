import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import ButtonBox from '../ButtonBox/ButtonBox'
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Map/phone.svg'
import email from '../../image/Map/email-icon.svg'
import address from '../../image/Map/address.svg'
import time from '../../image/Map/time.svg'
import company from '../../image/Map/company.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты <span>Я&#8205;-&#8205;STROY</span></motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79161705333" target='_blank' rel='noopener noreferrer'>+7 (916) 170-53-33</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="Ya-stroy02@yandex.ru" target='_blank' rel='noopener noreferrer'>Ya-stroy02@yandex.ru </a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={company} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>ИП Яппаров Ильнар Галиевич, ИНН&#160;024200119797</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={address} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>г. Москва, ул. Звёздный бульвар, д.21, стр. 3, этаж&#160;3, офис&#160;303</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Пт 09:00 - 21:00 Сб&#8205;-&#8205;Вс&#160;10:00&#160;-&#160;19:00</p>
                            <ButtonBox/>
                    </motion.div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default Map